import CustomHeaderForGuest from "src/components/CustomHeader/CustomHeaderForGuest";

const publicIp = require('public-ip')
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ChildDataProps, withApollo, graphql, compose, QueryOpts } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import gql from 'graphql-tag'
import {
    Button,
    Container,
    Grid,
    Form,
    Header,
    Icon,
    Input,
    Image,
    Label,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Transition,
} from 'semantic-ui-react'

import { Mixpanel } from 'src/mixpanel'
import { generatePageTitle } from 'src/utils'
import Footer from 'src/components/Footer'
import CustomHeader from 'src/components/CustomHeader'
import {
    ABOUT_PAGE_LOADED,
    USERNAME,
    EMAIL,
    GENDER,
    DATE_JOINED,
    LAST_LOGIN,
    CLIENT_ID,
    USER_SESSION,
    FROM,
    EVENT_DATE,
    EVENT_TIME,
    BORN_YEAR,
    OS_VERSION,
} from 'src/constants'
import './style.css'

const subjectQuery = gql`
  query publicSubjects($subjectSlug: String!) {
    publicSubjects(subjectSlug: $subjectSlug) {
      id
      displayName
      bannerImgUrl
      slug
      aboutText
    }
  }
`

type SubjectQueryParams = {
    subjectSlug: string
}

type SubjectQueryResponse = {
    publicSubjects: [
        {
            id: number
            displayName: string
            aboutText: string
            bannerImgUrl: string
        }
    ]
}



//for mixpanel logging
var clientID = ''
var strDate: string | null | undefined
var strTime: string | null | undefined
var versionOs: any

type OwnProps = RouteComponentProps<SubjectQueryParams>
type SubjectQueryProps = ChildDataProps<SubjectQueryParams, SubjectQueryResponse> & {
    subjectQuery: Function
}
type Props = SubjectQueryProps & OwnProps

type ChildProps = ChildDataProps<{}, SubjectQueryResponse, SubjectQueryParams>

const detectOS = () => {
    var unknown = '-'

    // browser
    var nVer = navigator.appVersion
    var nAgt = navigator.userAgent

    // system
    var os = unknown
    var clientStrings = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Chrome OS', r: /CrOS/ },
        { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        {
            s: 'Search Bot',
            r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
        },
    ]
    for (var id in clientStrings) {
        var cs = clientStrings[id]
        if (cs.r.test(nAgt)) {
            os = cs.s
            break
        }
    }

    var osVersion: any = unknown

    if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)![1]
        os = 'Windows'
    }

    switch (os) {
        case 'Mac OS X':
            osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)![1]
            break

        case 'Android':
            osVersion = /Android ([\.\_\d]+)/.exec(nAgt)![1]
            break

        case 'iOS':
            osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)!
            osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0)
            break
    }
    versionOs = os + ' ' + osVersion
}

class AboutViewByGuest extends React.Component<Props, {}> {
    componentDidMount = async () => {
        let ipAddress = await publicIp.v4()

        if (ipAddress == null || ipAddress == undefined || ipAddress == '') {
            ipAddress = await publicIp.v6()
        }

        Mixpanel.identify(ipAddress)
        Mixpanel.people.set({ $name: ipAddress })

        var date = new Date()
        this.formatDate(date)
        var mainData = {
            [CLIENT_ID]: this.generateMixpanelValues(clientID),
            [EVENT_DATE]: this.generateMixpanelValues(strDate),
            [EVENT_TIME]: this.generateMixpanelValues(strTime),
            [FROM]: this.generateMixpanelValues('Website'),
            [OS_VERSION]: this.generateMixpanelValues(versionOs),
        }
        Mixpanel.track(ABOUT_PAGE_LOADED, mainData)
    }

    generateMixpanelValues(value: string | null | undefined) {
        if (
            value != null &&
            value != '' &&
            value != 'undefined' &&
            value != undefined &&
            value != 'null'
        )
            return value
        else return 'N/A'
    }

    //formating date time
    formatDate(date: any) {
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var seconds = date.getSeconds()
        var month = date.getMonth() + 1
        var day = date.getDate()
        var year = date.getFullYear()
        // strDate = (month > 9 ? month : "0" + month) + "-" + (day > 9 ? day : "0" + day) + "-" + year;
        strDate = month + '-' + day + '-' + year
        strTime =
            (hours > 9 ? hours : '0' + hours) +
            ':' +
            (minutes > 9 ? minutes : '0' + minutes) +
            ':' +
            (seconds > 9 ? seconds : '0' + seconds)
        return date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear() + '-' + strTime
    }

    render() {
        const { loading } = this.props.data
        const publicSubjects = this.props.data.publicSubjects && this.props.data.publicSubjects[0]
        detectOS()

        const header = localStorage.getItem('jwt') ?  <CustomHeader title={`About ${publicSubjects && publicSubjects.displayName}`}  /> :  <CustomHeaderForGuest title={`About ${publicSubjects && publicSubjects.displayName}`}  />

        return (
            <div id="about-view">
                <Helmet>
                    <title>{generatePageTitle('About')}</title>
                </Helmet>
                <Responsive {...Responsive.onlyMobile}>
                    {header}
                    {publicSubjects && publicSubjects.bannerImgUrl && (
                        <>
                            <img className="banner" src={publicSubjects.bannerImgUrl} />
                            <br />
                        </>
                    )}
                    <Container className="main-container">
                        <Form loading={loading}>
                            <p dangerouslySetInnerHTML={{ __html: publicSubjects ? publicSubjects.aboutText : '' }} />
                        </Form>
                    </Container>
                </Responsive>
                <Responsive {...Responsive.onlyTablet}>
                    {header}
                    {publicSubjects && publicSubjects.bannerImgUrl && (
                        <>
                            <img className="banner" src={publicSubjects.bannerImgUrl} />
                            <br />
                        </>
                    )}
                    <Container className="main-container">
                        <Form loading={loading}>
                            <p dangerouslySetInnerHTML={{ __html: publicSubjects ? publicSubjects.aboutText : '' }} />
                        </Form>
                    </Container>
                </Responsive>
                <Responsive {...Responsive.onlyComputer} className="desktop" as={Form} loading={loading}>
                    <div className="main-content-holder">
                        {header}
                        <Container className="main-container">
                            {publicSubjects && publicSubjects.bannerImgUrl && (
                                <div className="banner-div">
                                    <img className="banner" src={publicSubjects.bannerImgUrl} />
                                </div>
                            )}
                            <Grid className="header-controls-grid">
                                <Grid.Row>
                                    <Grid.Column width={8} className="header-col">
                                        <Header as="h1">{`About ${publicSubjects && publicSubjects.displayName}`}</Header>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <p dangerouslySetInnerHTML={{ __html: publicSubjects ? publicSubjects.aboutText : '' }} />
                        </Container>
                    </div>
                    <Footer />
                </Responsive>
            </div>
        )
    }
}

export default graphql<Props, SubjectQueryResponse>(subjectQuery, {
    options: (props: OwnProps): QueryOpts<SubjectQueryParams> => ({
        variables: {
            subjectSlug: props.match.params.subjectSlug,
        },
    }),
})(AboutViewByGuest)
