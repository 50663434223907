import mixpanel from "mixpanel-browser";

let API_KEY = process.env.REACT_APP_MIXPANEL_API_KEY;
mixpanel.init(API_KEY);

//  always track but access token get from env depend on mode   dev = AN_dev, prod = AN-Prodaction
let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },

  track: (name, props) => {
    mixpanel.track(name, props);
  },

  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
    increment: (props, time) => {
      mixpanel.people.increment(props, time);
    },
  },
};

export let Mixpanel = actions;
