export const PAGE_TITLE_PREFIX = 'AuraNexus'

// Paths
export const ROOT_PATH = '/'

//TODO: Hrak: Cleanup
// Hrak: Done
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

//TODO: Hrak: This requires a cleanup, Get the domain from URL not from env.
// Hrak: Done
export const DOMAIN = window.location.hostname

// constant values in site
export const DISCLAIMER_TEXT_COVID19 = 'The information/responses you will hear are solely those of each individual, and do not reflect the opinions or positions of their employers/organizations, AuraNexus, or any related parties. Please consult your physician before taking any medical actions.'
export const TOP_CONTAINER_TEXT_FOR_COVID19 = 'AuraNexus is an interactive conversational video platform powered by your voice. During this time of crisis, we are devoting our efforts to leverage our technology and provide a platform for medical experts and those with first-hand knowledge of the crisis to help answer the many questions you have regarding COVID-19. Click on any of the experiences below to speak with an E.R. doctor, an infectious disease epidemiologist, and a COVID-19/pneumonia survivor. Check back often as more experiences will be added. If you are interested in providing your voice to the platform, please email us at '
export const AURANEXUS_INFO_MAIL_ID = "info@auranexus.com"
export const TOP_CONTAINER_HEADER_TEXT_FOR_COVID19 = "Let's talk about COVID-19!"
// Mixpanel events
export const CHOOSE_EXPERIENCE_PAGE_LOADED = 'CHOOSE_EXPERIENCE_PAGE_LOADED'
export const LOGIN_PAGE_LOADED = 'LOGIN_PAGE_LOADED'
export const LOGIN_EMAIL_PAGE_LOADED = 'LOGIN_EMAIL_PAGE_LOADED'
export const SIGNUP_EMAIL_PAGE_LOADED = 'SIGNUP_EMAIL_PAGE_LOADED'
export const LOGOUT_PAGE_LOADED = 'LOGOUT_PAGE_LOADED'
export const GOOGLE_OAUTH_REDIRECT_PAGE_LOADED = 'GOOGLE_OAUTH_REDIRECT_PAGE_LOADED'
export const VIEW_EXPERIENCE_PAGE_LOADED = 'AURANEXUS'
export const VIEW_EXPERIENCE_QUESTION_ASKED = 'VIEW_EXPERIENCE_QUESTION_ASKED'
export const VIEW_EXPERIENCE_ANSWER_VIDEO_STARTED = 'VIEW_EXPERIENCE_ANSWER_VIDEO_STARTED'
export const VIEW_EXPERIENCE_ANSWER_VIDEO_ENDED = 'VIEW_EXPERIENCE_ANSWER_VIDEO_ENDED'
export const VIEW_EXPERIENCE_INTRO_VIDEO_ENDED = 'VIEW_EXPERIENCE_INTRO_VIDEO_ENDED'
export const ABOUT_PAGE_LOADED = 'ABOUT_PAGE_LOADED'
export const THAT_DIDNOT_WORK = "That didn't work, try again."
export const NA_TEXT = 'N/A'
export const VIDEO_INTERRUPTED = 'VIDEO INTERRUPTED'
export const QUESTION_ASKED = 'QUESTION ASKED'
export const RESPONSE_WATCHED = 'RESPONSE WATCHED'
export const CHOOSE_CATEGORY_PAGE_LOADED = "CATEGORY_PAGE_LOADED"

//mixpanel properties
export const USERNAME = 'Username'
export const EMAIL = 'Email'
export const GENDER = 'Gender'
export const DATE_JOINED = 'Date Joined'
export const LAST_LOGIN = 'Last Login'
export const CLIENT_ID = 'Client ID'
export const USER_SESSION = 'User Session'
export const FROM = 'From'
export const VIDEO_NAME = 'Video Name'
export const CLIP_NAME = 'Clip Name'
export const ASKED_QUESTION = 'Asked Question'
export const RESPONSE_START = 'Response Start'
export const RESPONSE_END = 'Response End'
export const RESPONSE_RECEIVED = 'Response Received'
export const TOTAL_SECONDS_WATCHED = 'Total Seconds Watched'
export const TIME_TAKEN_TO_ASK_QUESTION = 'Time Taken To Ask Question'
export const INTERRUPTED_TIME = 'Interrupted Time'
export const EVENT_DATE = 'Date'
export const EVENT_TIME = 'Event Time'
export const VIDEO_INTERRUPTED_COUNT = 'Video Interrupted Count'
export const ORIGINAL_QUESTION = 'Original Question'
export const BORN_YEAR = 'Year Born'
export const OS_VERSION = 'OS Version'
