const publicIp = require('public-ip')
import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {QueryOpts, graphql, compose} from 'react-apollo'
import gql from 'graphql-tag'
import {
    Container,
    Divider,
    Grid,
    Form,
    Header,
    Icon,
    Input,
    Image,
    Label,
    Popup,
    Responsive,
} from 'semantic-ui-react'
import {Modal} from 'react-responsive-modal'

import {Mixpanel} from 'src/mixpanel'
import {generatePageTitle} from 'src/utils'
import './style.css'
import './style.scss'
import CustomHeader from 'src/components/CustomHeader'
import Footer from 'src/components/Footer'
import {
    CHOOSE_CATEGORY_PAGE_LOADED,
    USERNAME,
    EMAIL,
    GENDER,
    DATE_JOINED,
    LAST_LOGIN,
    CLIENT_ID,
    USER_SESSION,
    FROM,
    EVENT_DATE,
    EVENT_TIME,
    BORN_YEAR,
    OS_VERSION,
} from 'src/constants'

import {Carousel} from 'react-responsive-carousel'

const COLUMNS_MOBILE = 2
const COLUMNS_DESKTOP = 3


const userCategory = gql`
  query{
    usercategories{
      id
      imageUrl
      displayName
      slug
    }
  }
`

const publicCategories = gql`
 query{
  publicCategories{
    id,
    slug,
    displayName
  }
}
`

const categorySubjects = gql`
  query categorysubjects($categorySlug: String!) {
    categorysubjects(categorySlug: $categorySlug) {
      id
      slug
      metadata
      shortText
      thumbnailUrl
      displayName
      clipUrl
      category{
        displayName
        slug
      }
    }
  }
`

const watsonMutation = gql`
  mutation fetchWatsonToken {
    fetchWatsonToken {
      watsonAccessToken
    }
  }
`

const pablicSubjects = gql`
query{
  publicSubjects{
    id,
    slug,
    metadata,
    shortText,
    displayName,
    thumbnailUrl,
    clipUrl,
    category{
        displayName
        slug
      }
  }
}
`

type Subject = {
    id: number
    displayName: string
    slug: string
    metadata: string
    shortText: string
    thumbnailUrl: string
    clipUrl: string
    category: {
        displayName: string
        slug: string
    }
}

type Category = {
    id: number
    imageUrl: string
    metadata: string
    displayName: string
    slug: string
}

type UserCategorySubjectsParams = {
    // jwt: string
    categorySlug: string
}

type State = {
    searchText: string
    showCategoryCount: number
    showSubjectsCount: number
    totalSubjects: number
    totalCategories: number
    currentShortText: string
    openModal: boolean
}




class DashboardView extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.handleShowMore = this.handleShowMore.bind(this)
        this.handleShowLess = this.handleShowLess.bind(this)
        this.handleShowMoreSubjects = this.handleShowMoreSubjects.bind(this)
        this.handleShowLessSubjects = this.handleShowLessSubjects.bind(this)
    }
    
    userData: any
    userSession = ''
    clientID = ''
    strDate: string | null | undefined
    strTime: string | null | undefined
    versionOs: any


    state: State = {
        searchText: '',
        showCategoryCount: 3,
        showSubjectsCount: 3,
        totalSubjects: 0,
        totalCategories: 0,
        openModal: false,
        currentShortText: ''
    }
    detectOS = () => {
        var unknown = '-'

        // browser
        var nVer = navigator.appVersion
        var nAgt = navigator.userAgent

        // system
        var os = unknown
        var clientStrings = [
            {s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/},
            {s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/},
            {s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/},
            {s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/},
            {s: 'Windows Vista', r: /Windows NT 6.0/},
            {s: 'Windows Server 2003', r: /Windows NT 5.2/},
            {s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/},
            {s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/},
            {s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/},
            {s: 'Windows 98', r: /(Windows 98|Win98)/},
            {s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/},
            {s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
            {s: 'Windows CE', r: /Windows CE/},
            {s: 'Windows 3.11', r: /Win16/},
            {s: 'Android', r: /Android/},
            {s: 'Open BSD', r: /OpenBSD/},
            {s: 'Sun OS', r: /SunOS/},
            {s: 'Chrome OS', r: /CrOS/},
            {s: 'Linux', r: /(Linux|X11(?!.*CrOS))/},
            {s: 'iOS', r: /(iPhone|iPad|iPod)/},
            {s: 'Mac OS X', r: /Mac OS X/},
            {s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
            {s: 'QNX', r: /QNX/},
            {s: 'UNIX', r: /UNIX/},
            {s: 'BeOS', r: /BeOS/},
            {s: 'OS/2', r: /OS\/2/},
            {
                s: 'Search Bot',
                r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
            },
        ]
        for (var id in clientStrings) {
            var cs = clientStrings[id]
            if (cs.r.test(nAgt)) {
                os = cs.s
                break
            }
        }

        var osVersion: any = unknown

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)![1]
            os = 'Windows'
        }

        switch (os) {
            case 'Mac OS X':
                osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)![1]
                break

            case 'Android':
                osVersion = /Android ([\.\_\d]+)/.exec(nAgt)![1]
                break

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)!
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0)
                break
        }
        this.versionOs = os + ' ' + osVersion
    }
    

    componentDidMount = async () => {
        let ipAddress = await publicIp.v4()

        if (ipAddress == null || ipAddress == undefined || ipAddress == '') {
            ipAddress = await publicIp.v6()
        }

        var date = new Date()
        var formattedDate = this.formatDate(date)
        let {data} = await this.props.watsonMutation()
        this.userData = data.user
        Mixpanel.identify(ipAddress)
        Mixpanel.people.set({$name: ipAddress})
        this.userSession = ipAddress + '-' + formattedDate
        sessionStorage.setItem('User-session', this.userSession)
        Mixpanel.track(CHOOSE_CATEGORY_PAGE_LOADED, {
            [USERNAME]: this.generateMixpanelValues(this.userData.first_name + ' ' + this.userData.last_name),
            [EMAIL]: this.generateMixpanelValues(this.userData.email),
            [GENDER]: this.generateMixpanelValues(this.userData.gender),
            [BORN_YEAR]: this.generateMixpanelValues(this.userData.year_of_birth),
            [DATE_JOINED]: this.generateMixpanelValues(this.userData.date_joined),
            [LAST_LOGIN]: this.generateMixpanelValues(this.userData.last_login),
            [CLIENT_ID]: this.generateMixpanelValues(this.clientID),
            [USER_SESSION]: this.generateMixpanelValues(this.userSession),
            [EVENT_DATE]: this.generateMixpanelValues(this.strDate),
            [EVENT_TIME]: this.generateMixpanelValues(this.strTime),
            [FROM]: 'Website',
            [OS_VERSION]: this.generateMixpanelValues(this.versionOs),
        })

        // Check browser support
        if (typeof Storage !== 'undefined' && data) {
            // Store
            sessionStorage.setItem('Firstname', this.userData.first_name)
            sessionStorage.setItem('Lastname', this.userData.last_name)
            sessionStorage.setItem('Is-active', this.userData.is_active)
            sessionStorage.setItem('Email', this.userData.email)
            sessionStorage.setItem('Gender', this.userData.gender)
            sessionStorage.setItem('Last-login', this.userData.last_login)
            sessionStorage.setItem('Date-joined', this.userData.date_joined)
        } else {
            console.log('Sorry, your browser does not support Web Storage...')
        }

    }

    generateMixpanelValues(value: string | null | undefined) {
        if (
            value != null &&
            value != '' &&
            value != 'null' &&
            value != 'undefined' &&
            value != undefined
        )
            return value
        else return 'N/A'
    }

    //formating date time
    formatDate(date: any) {
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var seconds = date.getSeconds()
        var month = date.getMonth() + 1
        var day = date.getDate()
        var year = date.getFullYear()
        // this.strDate = (month > 9 ? month : "0" + month) + "-" + (day > 9 ? day : "0" + day) + "-" + year;
        this.strDate = month + '-' + day + '-' + year
        this.strTime =
            (hours > 9 ? hours : '0' + hours) +
            ':' +
            (minutes > 9 ? minutes : '0' + minutes) +
            ':' +
            (seconds > 9 ? seconds : '0' + seconds)
        return date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear() + '-' + this.strTime
    }

    updateSearchText = (e: React.FormEvent<HTMLInputElement>) => {
        const {value, checked, type} = e.currentTarget
        const newVal = type === 'checkbox' ? checked : value
        this.setState({searchText: newVal})
    }

    handleShowMore() {
        this.setState({showCategoryCount: this.state.totalCategories})
    }

    handleShowLess() {
        this.setState({showCategoryCount: 3})
    }

    handleShowMoreSubjects() {
        this.setState({showSubjectsCount: this.state.totalSubjects})
    }

    handleShowLessSubjects() {
        this.setState({showSubjectsCount: 3})
    }

    //for open popup in mobile
    onOpenModel = (showText: any) => {
        console.log(showText);
        this.setState({
            openModal: true,
            currentShortText: showText
        });
    };

    //for close popup in mobile
    onCloseModal = () => {
        this.setState({openModal: false});
    };


    render() {
        const {loading} = this.props.usercategory
        const {searchText} = this.state
        let {usercategories} = this.props.usercategory
        let {publicCategories} = this.props.publicCategories
        let {categorysubjects} = this.props.categorysubjects
        let {publicSubjects} = this.props.pablicSubjects
        const {openModal} = this.state;
        let thumbs: Array<JSX.Element> = []

        const sliderSettings = {
            showStatus: false,
            showArrows: false,
            showThumbs: false,
            infiniteLoop: true,
            autoPlay: true,
        }

        if (usercategories && publicCategories) {
            this.state.totalCategories = usercategories.length + publicCategories.length
        }


        if (categorysubjects && publicSubjects) {
            this.state.totalSubjects = categorysubjects.length + publicSubjects.length
        }

        if (categorysubjects && this.state.searchText && this.state.searchText != null) {
            categorysubjects = categorysubjects.filter((subject: Subject) => {
                return subject.metadata.toUpperCase().includes(searchText.toUpperCase())
            })
        }

        if (usercategories && this.state.searchText && this.state.searchText != null) {
            usercategories = usercategories.filter((category: Category) => {
                return category.displayName.toUpperCase().includes(searchText.toUpperCase())
            })
        }


        let categoriesSubjectsDesktop =
            categorysubjects &&
            categorysubjects.slice(0, this.state.showSubjectsCount).map((subject: Subject, i: number) => (

                <Grid.Column className="image-col" key={i}>
                    <Popup
                        trigger={
                            <Link to={`private/categories/${subject.category.slug}/${subject.slug}`}>
                                {subject.thumbnailUrl ? (
                                    <Image className="subject-thumb-img" src={subject.thumbnailUrl} rounded/>
                                ) : (
                                    <div className="subject-thumb"></div>
                                )}
                                <p className="caption"><span className="nameBgColor">{subject.displayName}</span></p>
                            </Link>
                        }
                        content={subject.shortText}
                        position="bottom center"
                    />
                </Grid.Column>
            ))
        let publishCategoriesSubjectsDesktop = '';
        if (categorysubjects && publicSubjects) {
            publishCategoriesSubjectsDesktop =
                publicSubjects &&
                publicSubjects.slice(0, (this.state.showSubjectsCount - categorysubjects.length)).map((subject: Subject, i: number) => (
                    <Grid.Column className="image-col" key={i}>
                        <Popup
                            trigger={
                                <Link to={`/public/categories/${subject.category.slug}/${subject.slug}`}>
                                    {subject.thumbnailUrl ? (
                                        <Image className="subject-thumb-img" src={subject.thumbnailUrl} rounded/>
                                    ) : (
                                        <div className="subject-thumb"></div>
                                    )}
                                    <p className="caption"><span className="nameBgColor">{subject.displayName}</span>
                                    </p>
                                </Link>
                            }
                            content={subject.shortText}
                            position="bottom center"
                        />
                    </Grid.Column>
                ))
        }


        let publishCategoriesSubjectsMobile =
            publicSubjects &&
            publicSubjects.map((subject: Subject, i: number) => (
                <Grid.Column className="image-col" key={i}>
                    <Link to={`/public/categories/${subject.category.slug}/${subject.slug}`}>
                        {subject.thumbnailUrl ? (
                            <Image className="subject-thumb-img" src={subject.thumbnailUrl} rounded/>
                        ) : (
                            <div className="subject-thumb"></div>
                        )}
                        <p className="caption"><span className="nameBgColor">{subject.displayName}</span></p>
                    </Link>
                    <div className="popupButtonDivStyles">
                        <Icon name="info circle" className="popupButtonStyles"
                              onClick={() => this.onOpenModel(subject.shortText)}/>
                    </div>
                </Grid.Column>
            ))

        let categoriesSubjectsMobile =
            categorysubjects &&
            categorysubjects.map((subject: Subject, i: number) => (
                <Grid.Column className="image-col" key={i}>
                    <Link to={`/private/categories/${subject.category.slug}/${subject.slug}`}>
                        {subject.thumbnailUrl ? (
                            <Image className="subject-thumb-img" src={subject.thumbnailUrl} rounded/>
                        ) : (
                            <div className="subject-thumb"></div>
                        )}
                        <p className="caption"><span className="nameBgColor">{subject.displayName}</span></p>
                    </Link>
                    <div className="popupButtonDivStyles">
                        <Icon name="info circle" className="popupButtonStyles"
                              onClick={() => this.onOpenModel(subject.shortText)}/>
                    </div>
                </Grid.Column>
            ))


        let categoriesDesktop =
            usercategories &&
            usercategories.slice(0, this.state.showCategoryCount).map((category: Category, i: number) => (
                <Grid.Column className="image-col" key={i}>
                    <Link to={`/private/categories/${category.slug}`}>
                        {category.imageUrl ? (
                            <Image className="subject-thumb-img" src={category.imageUrl} rounded/>
                        ) : (
                            <div className="subject-thumb"></div>
                        )}

                        <p className="caption"><span className="nameBgColor">{category.displayName}</span></p>
                    </Link>
                </Grid.Column>
            ))

        let publicCategoriesDesktop = '';

        if (usercategories && publicCategories) {
            publicCategoriesDesktop =
                publicCategories &&
                publicCategories.slice(0, (this.state.showCategoryCount - usercategories.length)).map((category: Category, i: number) => (
                    <Grid.Column className="image-col" key={i}>
                        <Link to={`/public/categories/${category.slug}`}>
                            {category.imageUrl ? (
                                <Image className="subject-thumb-img" src={category.imageUrl} rounded/>
                            ) : (
                                <div className="subject-thumb"></div>
                            )}

                            <p className="caption"><span className="nameBgColor">{category.displayName}</span></p>
                        </Link>
                    </Grid.Column>
                ))
        }


        let categoriesMobile =
            usercategories &&
            usercategories.map((category: Category, i: number) => (
                <Grid.Column className="image-col" key={i}>
                    <Link to={`/private/categories/${category.slug}`}>
                        {category.imageUrl ? (
                            <Image className="subject-thumb-img" src={category.imageUrl} rounded/>
                        ) : (
                            <div className="subject-thumb"></div>
                        )}
                        <p className="caption"><span className="nameBgColor">{category.displayName}</span></p>
                    </Link>
                </Grid.Column>
            ))

        let publicCategoriesMobile =
            publicCategories &&
            publicCategories.map((category: Category, i: number) => (
                <Grid.Column className="image-col" key={i}>
                    <Link to={`/public/categories/${category.slug}`}>
                        {category.imageUrl ? (
                            <Image className="subject-thumb-img" src={category.imageUrl} rounded/>
                        ) : (
                            <div className="subject-thumb"></div>
                        )}
                        <p className="caption"><span className="nameBgColor">{category.displayName}</span></p>
                    </Link>
                </Grid.Column>
            ))
        this.detectOS()
        return (
            <>
                <div id="dashboard-view">
                    <Helmet>
                        <title>{generatePageTitle('Categories')}</title>
                    </Helmet>
                    <Responsive className="mobile-top-level-container" {...Responsive.onlyMobile}>
                        <CustomHeader/>
                        <Container className="main-container">
                            <Modal open={openModal} onClose={this.onCloseModal} center>
                                <div className="modalTextStyles">
                                    {this.state.currentShortText}
                                </div>
                            </Modal>
                            <Form loading={loading}>
                                <Input
                                    className="search-box"
                                    icon="search"
                                    placeholder="Search..."
                                    fluid
                                    onChange={this.updateSearchText}
                                    value={searchText}
                                />
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header>Recently added</Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Divider/>
                                <Grid columns={COLUMNS_MOBILE} className="main-grid">
                                    {categoriesSubjectsMobile}
                                    {publishCategoriesSubjectsMobile}
                                </Grid>
                                <Divider/>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header>Categories</Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Divider/>
                                <Grid columns={COLUMNS_MOBILE} className="main-grid">
                                    {categoriesMobile}
                                    {publicCategoriesMobile}
                                </Grid>
                                <Divider/>
                            </Form>
                        </Container>
                    </Responsive>
                    <Responsive className="mobile-top-level-container" {...Responsive.onlyTablet}>
                        <CustomHeader/>
                        <Container className="main-container">
                            <Modal open={openModal} onClose={this.onCloseModal} center>
                                <div className="modalTextStyles">
                                    {this.state.currentShortText}
                                </div>
                            </Modal>
                            <Form loading={loading}>
                                <Input
                                    className="search-box"
                                    icon="search"
                                    placeholder="Search..."
                                    fluid
                                    onChange={this.updateSearchText}
                                    value={searchText}
                                />
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header>Recently added</Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Divider/>
                                <Grid columns={COLUMNS_MOBILE} className="main-grid">
                                    {categoriesSubjectsMobile}
                                    {publishCategoriesSubjectsMobile}
                                </Grid>
                                <Divider/>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header>Categories</Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Divider/>
                                <Grid columns={COLUMNS_MOBILE} className="main-grid">
                                    {categoriesMobile}
                                    {publicCategoriesMobile}
                                </Grid>
                                <Divider/>
                            </Form>
                        </Container>
                    </Responsive>
                    <Responsive
                        as={Form}
                        loading={loading}
                        className="desktop-top-level-container"
                        {...Responsive.onlyComputer}
                    >
                        <Grid className="background-pattern">
                            <Grid.Row>
                                <Grid.Column width={5} className="gray-bar"/>
                                <Grid.Column width={11}/>
                            </Grid.Row>
                        </Grid>
                        <div className="main-content-holder">
                            <CustomHeader/>
                            <Container className="main-container">
                                <Grid relaxed>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <Carousel {...sliderSettings} interval={6000}>
                                                {categorysubjects &&
                                                categorysubjects.map((subject: Subject, i: number) => (
                                                    <Link
                                                        to={`/private/categories/${subject.category.slug}/${subject.slug}`}
                                                        key={i}
                                                    >
                                                        {subject.thumbnailUrl ? (
                                                            <Image src={subject.thumbnailUrl} rounded/>
                                                        ) : (
                                                            <div className="bgimg"></div>
                                                        )}
                                                        <div className="catCarouselTextsContainer">
                                                            <div className="catPosition">
                                                                <span
                                                                    className="categoryDiv">{subject.category.displayName}</span>
                                                            </div>
                                                            <div className="namePosition">{subject.displayName}</div>
                                                            <div className="shortTextPosition">{subject.shortText}</div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Carousel>
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={8} className="header-col">
                                                        <Header as="h1">Recently Added</Header>
                                                    </Grid.Column>
                                                    {this.state.totalSubjects > 3 ? (
                                                        <Grid.Column width={8} textAlign="right">
                                                            {this.state.showSubjectsCount > 3 ? (
                                                                <Label className="showMoreLink"
                                                                       onClick={this.handleShowLessSubjects}>
                                                                    LESS&nbsp;&nbsp;
                                                                    <Icon name="angle up"/>
                                                                </Label>
                                                            ) : (
                                                                <Label className="showMoreLink"
                                                                       onClick={this.handleShowMoreSubjects}>
                                                                    MORE&nbsp;&nbsp;
                                                                    <Icon name="angle down"/>
                                                                </Label>
                                                            )}
                                                        </Grid.Column>
                                                    ) : (<div></div>)}
                                                </Grid.Row>
                                            </Grid>
                                            <Grid columns={COLUMNS_DESKTOP}>
                                                {categoriesSubjectsDesktop}
                                                {publishCategoriesSubjectsDesktop}
                                            </Grid>
                                            <Divider/>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={8} className="header-col">
                                                        <Header as="h1">Categories</Header>
                                                    </Grid.Column>
                                                    {this.state.totalCategories > 3 ? (
                                                        <Grid.Column width={8} textAlign="right">
                                                            {this.state.showCategoryCount > 3 ? (
                                                                <Label className="showMoreLink"
                                                                       onClick={this.handleShowLess}>
                                                                    LESS&nbsp;&nbsp;
                                                                    <Icon name="angle up"/>
                                                                </Label>
                                                            ) : (
                                                                <Label className="showMoreLink"
                                                                       onClick={this.handleShowMore}>
                                                                    MORE&nbsp;&nbsp;
                                                                    <Icon name="angle down"/>
                                                                </Label>
                                                            )}
                                                        </Grid.Column>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </Grid.Row>
                                            </Grid>
                                            <Grid columns={COLUMNS_DESKTOP}>
                                                {categoriesDesktop}
                                                {publicCategoriesDesktop}
                                            </Grid>
                                            <Divider/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                        <Footer/>
                    </Responsive>
                </div>
            </>
        )
    }
}

export default compose(
    graphql(watsonMutation, {name: 'watsonMutation'}),

    graphql(categorySubjects, {
        name: 'categorysubjects',
        options: (props: any): QueryOpts<UserCategorySubjectsParams> => ({
            variables: {
                categorySlug: props.jwtToken,
            },
        }),
    }),

    graphql(pablicSubjects, {
        name: 'pablicSubjects',
    }),

    graphql(userCategory, {
        name: 'usercategory',
    }),

    graphql(publicCategories, {
        name: 'publicCategories',
    }),

)(DashboardView)
