import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import {
  Container,
  Divider,
  Grid,
  Form,
  Header,
  Icon,
  Input,
  Image,
  Label,
  Popup,
  Responsive,
} from "semantic-ui-react";
import { Modal } from "react-responsive-modal";

import { generatePageTitle } from "src/utils";
import CustomHeaderForGuest from "src/components/CustomHeader/CustomHeaderForGuest";
import CustomHeader from "src/components/CustomHeader";
import Footer from "src/components/Footer";

import { Carousel } from "react-responsive-carousel";

const COLUMNS_MOBILE = 2;
const COLUMNS_DESKTOP = 3;

const pablishSubjects = gql`
  query {
    publicSubjects {
      id
      slug
      metadata
      shortText
      displayName
      thumbnailUrl
      clipUrl
      category {
        displayName
        slug
      }
    }
  }
`;

const publicCategories = gql`
  query {
    publicCategories {
      id
      slug
      displayName
    }
  }
`;

type Subject = {
  id: number;
  displayName: string;
  slug: string;
  metadata: string;
  shortText: string;
  thumbnailUrl: string;
  clipUrl: string;
  category: {
    displayName: string;
    slug: string;
  };
};

type Category = {
  id: number;
  imageUrl: string;
  metadata: string;
  displayName: string;
  slug: string;
};

type State = {
  searchText: string;
  showCategoryCount: number;
  showSubjectsCount: number;
  totalSubjects: number;
  totalCategories: number;
  currentShortText: string;
  openModal: boolean;
};

// SEE TODO above; for now passing in 'any' for props and state type
class Home extends React.Component<any, any> {
  _check: any;
  versionOs: any;

  constructor(props: any) {
    super(props);
    this._check = true;
    if (!localStorage.getItem("jwt")) {
      this.handleShowMore = this.handleShowMore.bind(this);
      this.handleShowLess = this.handleShowLess.bind(this);
      this.handleShowMoreSubjects = this.handleShowMoreSubjects.bind(this);
      this.handleShowLessSubjects = this.handleShowLessSubjects.bind(this);
    } else {
      this._check = false;
    }
  }

  state: State = {
    searchText: "",
    showCategoryCount: 3,
    showSubjectsCount: 3,
    totalSubjects: 0,
    totalCategories: 0,
    openModal: false,
    currentShortText: "",
  };

  detectOS = () => {
    var unknown = "-";

    // browser
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;

    // system
    var os = unknown;
    var clientStrings = [
      { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
      { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
      { s: "Windows Vista", r: /Windows NT 6.0/ },
      { s: "Windows Server 2003", r: /Windows NT 5.2/ },
      { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
      { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
      { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
      { s: "Windows 98", r: /(Windows 98|Win98)/ },
      { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
      { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: "Windows CE", r: /Windows CE/ },
      { s: "Windows 3.11", r: /Win16/ },
      { s: "Android", r: /Android/ },
      { s: "Open BSD", r: /OpenBSD/ },
      { s: "Sun OS", r: /SunOS/ },
      { s: "Chrome OS", r: /CrOS/ },
      { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
      { s: "iOS", r: /(iPhone|iPad|iPod)/ },
      { s: "Mac OS X", r: /Mac OS X/ },
      { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: "QNX", r: /QNX/ },
      { s: "UNIX", r: /UNIX/ },
      { s: "BeOS", r: /BeOS/ },
      { s: "OS/2", r: /OS\/2/ },
      {
        s: "Search Bot",
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    for (var id in clientStrings) {
      var cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    var osVersion: any = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)![1];
      os = "Windows";
    }

    switch (os) {
      case "Mac OS X":
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)![1];
        break;

      case "Android":
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)![1];
        break;

      case "iOS":
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)!;
        osVersion =
          osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
        break;
    }
    this.versionOs = os + " " + osVersion;
  };

  componentDidMount() {
    if (localStorage.getItem("jwt")) {
      this.props.history.push("/categories");
      return;
    }
  }

  updateSearchText = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, checked, type } = e.currentTarget;
    const newVal = type === "checkbox" ? checked : value;
    this.setState({ searchText: newVal });
  };

  handleShowMore() {
    this.setState({ showCategoryCount: this.state.totalCategories });
  }

  handleShowLess() {
    this.setState({ showCategoryCount: 3 });
  }

  handleShowMoreSubjects() {
    this.setState({ showSubjectsCount: this.state.totalSubjects });
  }

  handleShowLessSubjects() {
    this.setState({ showSubjectsCount: 3 });
  }

  //for open popup in mobile
  onOpenModel = (showText: any) => {
    console.log(showText);
    this.setState({
      openModal: true,
      currentShortText: showText,
    });
  };

  //for close popup in mobile
  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    const { loading } = this.props.publicCategories;
    const { searchText } = this.state;
    // let { usercategory } = this.props.usercategory
    let { publicSubjects } = this.props.categorysubjects;
    let { publicCategories } = this.props.publicCategories;

    const { openModal } = this.state;

    const sliderSettings = {
      showStatus: false,
      showArrows: false,
      showThumbs: false,
      infiniteLoop: true,
      autoPlay: true,
    };
    if (publicCategories) {
      this.state.totalCategories = publicCategories.length;
    }

    if (publicSubjects) {
      this.state.totalSubjects = publicSubjects.length;
    }

    if (
      publicSubjects &&
      this.state.searchText &&
      this.state.searchText != null
    ) {
      publicSubjects = publicSubjects.filter((subject: Subject) => {
        return subject.metadata
          .toUpperCase()
          .includes(searchText.toUpperCase());
      });
    }

    if (
      publicCategories &&
      this.state.searchText &&
      this.state.searchText != null
    ) {
      publicCategories = publicCategories.filter((category: Category) => {
        return publicCategories.displayName
          .toUpperCase()
          .includes(searchText.toUpperCase());
      });
    }
    let categoriesSubjectsDesktop =
      publicSubjects &&
      publicSubjects
        .slice(0, this.state.showSubjectsCount)
        .map((subject: Subject, i: number) => (
          <Grid.Column className="image-col" key={i}>
            <Popup
              trigger={
                <Link
                  to={`/public/categories/${subject.category.slug}/${subject.slug}`}
                >
                  {subject.thumbnailUrl ? (
                    <Image
                      className="subject-thumb-img"
                      src={subject.thumbnailUrl}
                      rounded
                    />
                  ) : (
                    <div className="subject-thumb"></div>
                  )}
                  <p className="caption">
                    <span className="nameBgColor">{subject.displayName}</span>
                  </p>
                </Link>
              }
              content={subject.shortText}
              position="bottom center"
            />
          </Grid.Column>
        ));

    let categoriesSubjectsMobile =
      publicSubjects &&
      publicSubjects.map((subject: Subject, i: number) => (
        <Grid.Column className="image-col" key={i}>
          <Link
            to={`/public/categories/${subject.category.slug}/${subject.slug}`}
          >
            {subject.thumbnailUrl ? (
              <Image
                className="subject-thumb-img"
                src={subject.thumbnailUrl}
                rounded
              />
            ) : (
              <div className="subject-thumb"></div>
            )}
            <p className="caption">
              <span className="nameBgColor">{subject.displayName}</span>
            </p>
          </Link>
          <div className="popupButtonDivStyles">
            <Icon
              name="info circle"
              className="popupButtonStyles"
              onClick={() => this.onOpenModel(subject.shortText)}
            />
          </div>
        </Grid.Column>
      ));

    let categoriesDesktop =
      publicCategories &&
      publicCategories
        .slice(0, this.state.showCategoryCount)
        .map((category: Category, i: number) => (
          <Grid.Column className="image-col" key={i}>
            <Link to={`/public/categories/${category.slug}`}>
              {category.imageUrl ? (
                <Image
                  className="subject-thumb-img"
                  src={category.imageUrl}
                  rounded
                />
              ) : (
                <div className="subject-thumb"></div>
              )}

              <p className="caption">
                <span className="nameBgColor">{category.displayName}</span>
              </p>
            </Link>
          </Grid.Column>
        ));

    let categoriesMobile =
      publicCategories &&
      publicCategories.map((category: Category, i: number) => (
        <Grid.Column className="image-col" key={i}>
          <Link to={`/public/categories/${category.slug}`}>
            {category.imageUrl ? (
              <Image
                className="subject-thumb-img"
                src={category.imageUrl}
                rounded
              />
            ) : (
              <div className="subject-thumb"></div>
            )}
            <p className="caption">
              <span className="nameBgColor">{category.displayName}</span>
            </p>
          </Link>
        </Grid.Column>
      ));

    this.detectOS();

    const header = localStorage.getItem("jwt") ? (
      <CustomHeader />
    ) : (
      <CustomHeaderForGuest />
    );
    return (
      <>
        {this._check ? (
          <div id="dashboard-view">
            <Helmet>
              <title>{generatePageTitle("Categories")}</title>
            </Helmet>
            <Responsive
              className="mobile-top-level-container"
              {...Responsive.onlyMobile}
            >
              {header}
              <Container className="main-container">
                <Modal open={openModal} onClose={this.onCloseModal} center>
                  <div className="modalTextStyles">
                    {this.state.currentShortText}
                  </div>
                </Modal>
                <Form loading={loading}>
                  <Input
                    className="search-box"
                    icon="search"
                    placeholder="Search..."
                    fluid
                    onChange={this.updateSearchText}
                    value={searchText}
                  />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Recently added</Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  <Grid columns={COLUMNS_MOBILE} className="main-grid">
                    {categoriesSubjectsMobile}
                  </Grid>
                  <Divider />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Categories</Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  <Grid columns={COLUMNS_MOBILE} className="main-grid">
                    {categoriesMobile}
                  </Grid>
                  <Divider />
                </Form>
              </Container>
            </Responsive>
            <Responsive
              className="mobile-top-level-container"
              {...Responsive.onlyTablet}
            >
              {header}
              <Container className="main-container">
                <Modal open={openModal} onClose={this.onCloseModal} center>
                  <div className="modalTextStyles">
                    {this.state.currentShortText}
                  </div>
                </Modal>
                <Form loading={loading}>
                  <Input
                    className="search-box"
                    icon="search"
                    placeholder="Search..."
                    fluid
                    onChange={this.updateSearchText}
                    value={searchText}
                  />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Recently added</Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  <Grid columns={COLUMNS_MOBILE} className="main-grid">
                    {categoriesSubjectsMobile}
                  </Grid>
                  <Divider />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Categories</Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  <Grid columns={COLUMNS_MOBILE} className="main-grid">
                    {categoriesMobile}
                  </Grid>
                  <Divider />
                </Form>
              </Container>
            </Responsive>
            <Responsive
              as={Form}
              className="desktop-top-level-container"
              {...Responsive.onlyComputer}
            >
              <Grid className="background-pattern">
                <Grid.Row>
                  <Grid.Column width={5} className="gray-bar" />
                  <Grid.Column width={11} />
                </Grid.Row>
              </Grid>
              <div className="main-content-holder">
                {header}
                <Container className="main-container">
                  <Grid relaxed>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Carousel {...sliderSettings} interval={6000}>
                          {publicSubjects &&
                            publicSubjects.map(
                              (subject: Subject, i: number) => (
                                <Link
                                  to={`/public/categories/${subject.category.slug}/${subject.slug}`}
                                  key={i}
                                >
                                  {subject.thumbnailUrl ? (
                                    <Image src={subject.thumbnailUrl} rounded />
                                  ) : (
                                    <div className="bgimg"></div>
                                  )}
                                  <div className="catCarouselTextsContainer">
                                    <div className="catPosition">
                                      <span className="categoryDiv">
                                        {subject.category.displayName}
                                      </span>
                                    </div>
                                    <div className="namePosition">
                                      {subject.displayName}
                                    </div>
                                    <div className="shortTextPosition">
                                      {subject.shortText}
                                    </div>
                                  </div>
                                </Link>
                              )
                            )}
                        </Carousel>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={8} className="header-col">
                              <Header as="h1">Recently Added</Header>
                            </Grid.Column>
                            {this.state.totalSubjects > 3 ? (
                              <Grid.Column width={8} textAlign="right">
                                {this.state.showSubjectsCount > 3 ? (
                                  <Label
                                    className="showMoreLink"
                                    onClick={this.handleShowLessSubjects}
                                  >
                                    LESS&nbsp;&nbsp;
                                    <Icon name="angle up" />
                                  </Label>
                                ) : (
                                  <Label
                                    className="showMoreLink"
                                    onClick={this.handleShowMoreSubjects}
                                  >
                                    MORE&nbsp;&nbsp;
                                    <Icon name="angle down" />
                                  </Label>
                                )}
                              </Grid.Column>
                            ) : (
                              <div></div>
                            )}
                          </Grid.Row>
                        </Grid>
                        <Grid columns={COLUMNS_DESKTOP}>
                          {categoriesSubjectsDesktop}
                        </Grid>
                        <Divider />
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={8} className="header-col">
                              <Header as="h1">Categories</Header>
                            </Grid.Column>
                            {this.state.totalCategories > 3 ? (
                              <Grid.Column width={8} textAlign="right">
                                {this.state.showCategoryCount > 3 ? (
                                  <Label
                                    className="showMoreLink"
                                    onClick={this.handleShowLess}
                                  >
                                    LESS&nbsp;&nbsp;
                                    <Icon name="angle up" />
                                  </Label>
                                ) : (
                                  <Label
                                    className="showMoreLink"
                                    onClick={this.handleShowMore}
                                  >
                                    MORE&nbsp;&nbsp;
                                    <Icon name="angle down" />
                                  </Label>
                                )}
                              </Grid.Column>
                            ) : (
                              <div></div>
                            )}
                          </Grid.Row>
                        </Grid>
                        <Grid columns={COLUMNS_DESKTOP}>
                          {categoriesDesktop}
                        </Grid>
                        <Divider />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </div>
              <Footer />
            </Responsive>
          </div>
        ) : null}
      </>
    );
  }
}

export default compose(
  graphql(pablishSubjects, {
    name: "categorysubjects",
  }),
  graphql(publicCategories, {
    name: "publicCategories",
  })
)(Home);
