import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
const publicIp = require('public-ip')
import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  Responsive,
} from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { Mixpanel } from 'src/mixpanel'
import { generatePageTitle } from 'src/utils'
import Footer from 'src/components/Footer'
import { SERVER_URL } from 'src/constants'
import { LOGIN_PAGE_LOADED } from 'src/constants'
import logo from 'src/images/Aura-Logo-White.png'
import './style.css'

interface LoginJWT {
  jwt: string
  cat: string
}

class LoginView extends React.Component<RouteComponentProps<{}>, {}> {
  componentDidMount = async () => {

    console.log(this.props.history);

    let ipAddress = await publicIp.v4()

    if (ipAddress == null || ipAddress == undefined || ipAddress == '') {
      ipAddress = await publicIp.v6()
    }

    Mixpanel.identify(ipAddress)
    Mixpanel.people.set({ $name: ipAddress })

    Mixpanel.track(LOGIN_PAGE_LOADED)
    if (localStorage.getItem('jwt')) {
      this.props.history.push('/')
    }
  }

  startGoogleOAuth = () => {
    if (
      process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID &&
      process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI
    )
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=${encodeURIComponent(
        'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
      )}&state=${encodeURIComponent(this.props.location.search)}&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI
      )}&response_type=code&client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`
    else console.error('Google oauth env vars not set')
  }

  responseFacebook = async (response: any) => {
    const config = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        access_token: response.accessToken,
        query_params: this.props.location.search,
      }),
    }
    const r = await fetch(`${SERVER_URL}/accounts/facebook/`, config)
    const json: LoginJWT = await r.json()
    if (json.jwt) {
      localStorage.setItem('jwt', json.jwt)
      localStorage.setItem('cat', json.cat)
      this.props.history.push('/')
    }
  }

  render() {
    return (
      <div id="login-view">
        <Helmet>
          <title>{generatePageTitle('Login')}</title>
        </Helmet>
        <Responsive as={Grid} className="mobile-top-level-container" {...Responsive.onlyMobile}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className="top-half">
                <Image className="home-logo" src={logo} />
              </div>
              <div className="bottom-half">
                <Button
                  icon
                  className="google-sign-in"
                  primary
                  labelPosition="left"
                  size="large"
                  onClick={this.startGoogleOAuth}
                >
                  <Icon name="google" />
                  Sign in with Google
                </Button>
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  autoLoad={false}
                  disableMobileRedirect={true}
                  fields="first_name,last_name, email"
                  render={(renderProps: any) => (
                    <Button
                      icon
                      className="facebook-sign-in"
                      color="facebook"
                      labelPosition="left"
                      size="large"
                      onClick={renderProps.onClick}
                    >
                      <Icon name="facebook" />
                      Sign in with Facebook
                    </Button>
                  )}
                  callback={this.responseFacebook}
                />
                <Button
                  icon
                  as={Link}
                  className="email-sign-in"
                  color="violet"
                  labelPosition="left"
                  size="large"
                  to={`/login-email${this.props.location.search}`}
                >
                  <Icon name="mail" />
                  Sign in with Email
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Responsive>
        <Responsive as={Grid} className="mobile-top-level-container" {...Responsive.onlyTablet}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className="top-half">
                <Image className="home-logo" src={logo} />
              </div>
              <div className="bottom-half">
                <Button
                  icon
                  className="google-sign-in"
                  primary
                  labelPosition="left"
                  size="large"
                  onClick={this.startGoogleOAuth}
                >
                  <Icon name="google" />
                  Sign in with Google
                </Button>
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  autoLoad={false}
                  disableMobileRedirect={true}
                  fields="first_name,last_name, email"
                  render={(renderProps: any) => (
                    <Button
                      icon
                      className="facebook-sign-in"
                      color="facebook"
                      labelPosition="left"
                      size="large"
                      onClick={renderProps.onClick}
                    >
                      <Icon name="facebook" />
                      Sign in with Facebook
                    </Button>
                  )}
                  callback={this.responseFacebook}
                />
                <Button
                  icon
                  as={Link}
                  className="email-sign-in"
                  color="violet"
                  labelPosition="left"
                  size="large"
                  to={`/login-email${this.props.location.search}`}
                >
                  <Icon name="mail" />
                  Sign in with Email
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Responsive>
        <Responsive className="desktop-top-level-container" {...Responsive.onlyComputer}>
          <div className="main-content-holder">
            <Grid>
              <Grid.Row columns={1} className="column-logo">
                <Grid.Column>
                  <Image className="home-logo" src={logo} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={5}>
                <Grid.Column>
                  <Header as="h2" className="">
                    Welcome to AuraNexus
                  </Header>
                  <p>
                    Providing one on one conversational video experiences powered by your voice.
                  </p>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Button
                    icon
                    className="google-sign-in"
                    primary
                    labelPosition="left"
                    size="large"
                    onClick={this.startGoogleOAuth}
                  >
                    <Icon name="google" />
                    Sign in with Google
                  </Button>
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="first_name,last_name, email"
                    render={(renderProps: any) => (
                      <Button
                        icon
                        className="facebook-sign-in"
                        color="facebook"
                        labelPosition="left"
                        size="large"
                        onClick={renderProps.onClick}
                      >
                        <Icon name="facebook" />
                        Sign in with Facebook
                      </Button>
                    )}
                    callback={this.responseFacebook}
                  />
                  <Button
                    icon
                    as={Link}
                    className="email-sign-in"
                    color="violet"
                    labelPosition="left"
                    size="large"
                    to={`/login-email${this.props.location.search}`}
                  >
                    <Icon name="mail" />
                    Sign in with Email
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <Footer />
        </Responsive>
      </div>
    )
  }
}

export default withRouter(LoginView)
