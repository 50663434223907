import React from 'react'
const publicIp = require('public-ip')
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ChildDataProps, graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import {
  Button,
  Divider,
  Grid,
  Image,
  Message,
  Responsive,
} from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

import { RouteComponentProps } from 'react-router'

import { Mixpanel } from 'src/mixpanel'
import { generatePageTitle } from 'src/utils'
import Footer from 'src/components/Footer'
import { LOGIN_EMAIL_PAGE_LOADED } from 'src/constants'
import logo from 'src/images/Aura-Logo-White.png'
import './style.css'

type State = {
  isLoading: boolean
  email: string
  password: string
  errorMsg: string
}

const loginMutation = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      error
      token
      category
    }
  }
`

type LoginMutationResponse = {
  token: string
  error: string
  category: string
}

type LoginMutationParams = {
  email: string
  password: string
}

type OwnProps = RouteComponentProps<{}>
type Props = ChildDataProps<LoginMutationParams, LoginMutationResponse> & {
  loginMutation: Function
}

class LoginEmailView extends React.Component<OwnProps & Props, State> {
  state = {
    isLoading: false,
    email: '',
    password: '',
    errorMsg: '',
  }

  componentDidMount = async () => {
    let ipAddress = await publicIp.v4()

    if (ipAddress == null || ipAddress == undefined || ipAddress == '') {
      ipAddress = await publicIp.v6()
    }

    Mixpanel.identify(ipAddress)
    Mixpanel.people.set({ $name: ipAddress })

    Mixpanel.track(LOGIN_EMAIL_PAGE_LOADED)
    if (localStorage.getItem('jwt')) {
      this.props.history.push('/')
    }
  }

  onValidSubmit = async () => {
    this.setState({ errorMsg: '', isLoading: true })
    const { email, password } = this.state

    let { data } = await this.props.loginMutation({
      variables: { email, password },
    })
    this.setState({ isLoading: false })

    if (!data.loginUser.error) {
      localStorage.setItem('jwt', data.loginUser.token)
      localStorage.setItem('cat', data.loginUser.category)
      this.props.history.push(`/`)
    } else {
      this.setState({ errorMsg: 'There was an error logging in.' })
    }
  }

  onFieldChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.currentTarget
    const newVal = type === 'checkbox' ? checked : value
    this.setState(prevState => ({
      ...prevState,
      [name]: newVal,
    }))
  }

  render() {
    const { isLoading, errorMsg } = this.state

    return (
      <div id="login-email-view">
        <Helmet>
          <title>{generatePageTitle('Login with Email')}</title>
        </Helmet>
        <Responsive as={Grid} className="mobile-top-level-container" {...Responsive.onlyMobile}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className="main-content-holder">
                <Grid>
                  <Grid.Row columns={1} className="">
                    <Grid.Column>
                      <Link to={`/${this.props.location.search}`}>
                        <Image className="home-logo" src={logo} />
                      </Link>
                      <Form
                        className="form"
                        loading={isLoading}
                        onValidSubmit={this.onValidSubmit}
                        error={errorMsg ? true : false}
                      >
                        <Message error color="black" header="Oops" content={errorMsg} />
                        <Form.Input
                          name="email"
                          label="Email"
                          validations="isEmail"
                          placeholder="Email address"
                          required
                          onChange={this.onFieldChange}
                          validationErrors={{
                            isEmail: 'Email not valid',
                          }}
                        />
                        <Form.Input
                          name="password"
                          label="Password"
                          placeholder="Password"
                          required
                          type="password"
                          onChange={this.onFieldChange}
                        />
                        <Button content="Log in" size="large" disabled={isLoading} />

                        <Divider className="divider" />
                        <span className="signup-text">
                          Don't have an account?{' '}
                          <Link
                            className="signup-text"
                            to={`/signup-email${this.props.location.search}`}
                          >
                            Sign up with email here.
                          </Link>
                        </span>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Responsive>
        <Responsive as={Grid} className="mobile-top-level-container" {...Responsive.onlyTablet}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className="main-content-holder">
                <Grid>
                  <Grid.Row columns={1} className="">
                    <Grid.Column>
                      <Link to={`/${this.props.location.search}`}>
                        <Image className="home-logo" src={logo} />
                      </Link>
                      <Form
                        className="form"
                        loading={isLoading}
                        onValidSubmit={this.onValidSubmit}
                        error={errorMsg ? true : false}
                      >
                        <Message error color="black" header="Oops" content={errorMsg} />
                        <Form.Input
                          name="email"
                          label="Email"
                          validations="isEmail"
                          placeholder="Email address"
                          required
                          onChange={this.onFieldChange}
                          validationErrors={{
                            isEmail: 'Email not valid',
                          }}
                        />
                        <Form.Input
                          name="password"
                          label="Password"
                          placeholder="Password"
                          required
                          type="password"
                          onChange={this.onFieldChange}
                        />
                        <Button content="Log in" size="large" disabled={isLoading} />

                        <Divider className="divider" />
                        <span className="signup-text">
                          Don't have an account?{' '}
                          <Link
                            className="signup-text"
                            to={`/signup-email${this.props.location.search}`}
                          >
                            Sign up with email here.
                          </Link>
                        </span>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Responsive>
        <Responsive className="desktop-top-level-container" {...Responsive.onlyComputer}>
          <div className="main-content-holder">
            <Grid>
              <Grid.Row columns={1} className="">
                <Grid.Column>
                  <Link to={`/${this.props.location.search}`}>
                    <Image className="home-logo" src={logo} />
                  </Link>
                  <Form
                    className="form"
                    loading={isLoading}
                    onValidSubmit={this.onValidSubmit}
                    error={errorMsg ? true : false}
                  >
                    <Message error color="black" header="Oops" content={errorMsg} />
                    <Form.Input
                      name="email"
                      label="Email"
                      validations="isEmail"
                      placeholder="Email address"
                      required
                      onChange={this.onFieldChange}
                      validationErrors={{
                        isEmail: 'Email not valid',
                      }}
                    />
                    <Form.Input
                      name="password"
                      label="Password"
                      placeholder="Password"
                      required
                      type="password"
                      onChange={this.onFieldChange}
                    />
                    <Button content="Log in" size="large" disabled={isLoading} />

                    <Divider className="divider" />
                    <span className="signup-text">
                      Don't have an account?{' '}
                      <Link
                        className="signup-text"
                        to={`/signup-email${this.props.location.search}`}
                      >
                        Sign up with email here.
                      </Link>
                    </span>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <Footer />
        </Responsive>
      </div>
    )
  }
}

export default compose(graphql(loginMutation, { name: 'loginMutation' }))(LoginEmailView)
