import { ApolloClient } from "apollo-client";
import React from "react";
import { ApolloLink } from "apollo-link";
import { ApolloProvider } from "react-apollo";
import { createHttpLink } from "apollo-link-http";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { DefaultOptions } from "apollo-client/ApolloClient";
import ChooseExperienceView from "./views/ChooseExperience/index";
import ChooseExperienceViewGuest from "./views/ChooseExperience/ChooseExperienceViewGuest";

import LoginView from "./views/Login/index";
import LoginEmailView from "./views/LoginEmail/index";
import SignupEmailView from "./views/SignupEmail/index";
import LogoutView from "./views/Logout/index";
import AboutView from "./views/About/index";
import AboutViewByGuest from "src/views/About/aboutByGuest";
import GoogleOauthRedirectView from "./views/GoogleOauthRedirect/index";
import ViewExperienceView from "./views/ViewExperience/index";
import ViewExperienceViewGuest from "./views/ViewExperience/forGuest";
import DashboardView from "./views/Dashboard/index";
import Home from "./views/Home/index";
import { ROOT_PATH, SERVER_URL } from "./constants";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "semantic-ui-css/semantic.min.css";
import "react-responsive-modal/styles.css";
import "./App.css";

declare global {
  interface Window {
    __APOLLO_STATE__: any;
  }
}

const globalAny: any = global;
const httpLink = createHttpLink({
  uri: `${SERVER_URL}/graphql/`,
});
const middlewareLink = new ApolloLink((operation, forward: any) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem("jwt")
        ? `JWT ${localStorage.getItem("jwt")}`
        : null,
    },
  });
  return forward(operation);
});

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors && graphQLErrors[0].message === "Signature has expired") {
      localStorage.removeItem("jwt");
      return globalAny.window.location.replace(`/login/?expired=true`);
    }
  }
);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([middlewareLink, errorLink, httpLink]),
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
});

const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (!localStorage.getItem("jwt") || !localStorage.getItem("cat")) {
        return <Redirect to={`/login${props.location.search}`} />;
      }

      return <Component {...props} />;
    }}
  />
);

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path="/login" exact component={LoginView} />
          <Route path="/login-email" exact component={LoginEmailView} />
          <Route path="/signup-email" exact component={SignupEmailView} />
          <Route
            path="/oauth2callback"
            exact
            component={GoogleOauthRedirectView}
          />
          <Route path="/logout" exact component={LogoutView} />
          <Route path="/" exact component={Home} />
          <PrivateRoute
            path="/private/categories/:categorySlug"
            exact
            component={ChooseExperienceView}
          />
          <Route
            path="/public/categories/:categorySlug"
            exact
            component={ChooseExperienceViewGuest}
          />
          <PrivateRoute
            path={`/private/categories/:categorySlug/:subjectSlug`}
            exact
            component={ViewExperienceView}
          />
          <Route
            path={`/public/categories/:categorySlug/:subjectSlug`}
            exact
            component={ViewExperienceViewGuest}
          />
          <PrivateRoute
            path={`/private/categories/:categorySlug/:subjectSlug/about`}
            exact
            component={AboutView}
          />
          <Route
            path={`/public/categories/:categorySlug/:subjectSlug/about`}
            exact
            component={AboutViewByGuest}
          />
          <PrivateRoute
            path={`/categories`}
            exact
            component={(props: any) => (
              <DashboardView
                jwtToken={localStorage.getItem("jwt")}
                {...props}
              />
            )}
          />
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default App;
