import React from 'react'
import { Container, Grid, Icon, Image, Menu, Responsive, Segment } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import './style.css'

class Footer extends React.Component<RouteComponentProps<{}>, {}> {
  render() {
    return (
      <Segment inverted id="footer-component">
        <Container>
          <Grid columns="equal">
            <Grid.Column>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScoQsJD48oLcLsCqYROkn793FdZVgw9yuYn4Q8aupsGuH_pPA/viewform"
                target="_blank"
              >
                <Icon name="flag" />
                Submit feedback
              </a>
            </Grid.Column>
            <Grid.Column textAlign="right">© 2020 AuraNexus, Inc.</Grid.Column>
          </Grid>
        </Container>
      </Segment>
    )
  }
}

export default withRouter(Footer)
