import CustomHeaderForGuest from "../../components/CustomHeader/CustomHeaderForGuest";

const publicIp = require('public-ip')
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { QueryOpts, ChildDataProps, graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Container,
    Divider,
    Grid,
    Form,
    Header,
    Icon,
    Image,
    Popup,
    Responsive,
} from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router'
import { Modal } from 'react-responsive-modal'

import { Mixpanel } from 'src/mixpanel'
import { generatePageTitle } from 'src/utils'
import './style.css'
import CustomHeader from 'src/components/CustomHeader'
import Footer from 'src/components/Footer'
import {
    CHOOSE_EXPERIENCE_PAGE_LOADED,
    CLIENT_ID,
    FROM,
    EVENT_DATE,
    EVENT_TIME,
    DISCLAIMER_TEXT_COVID19,
    TOP_CONTAINER_TEXT_FOR_COVID19,
    AURANEXUS_INFO_MAIL_ID,
    TOP_CONTAINER_HEADER_TEXT_FOR_COVID19,
    OS_VERSION,
} from 'src/constants'

const COLUMNS_MOBILE = 2
const COLUMNS_DESKTOP = 3

const categoryQuery = gql`
  query publicCategories{
    publicCategories{
      id
      imageUrl
    }
  }
`

const subjectsQuery = gql`
  query publicSubjects($categorySlug: String!) {
    publicSubjects(categorySlug: $categorySlug) {
      id
      slug
      metadata
      shortText
      thumbnailUrl
      displayName
      clipUrl
    }
  }
`


type Subject = {
    id: number
    displayName: string
    slug: string
    metadata: string
    shortText: string
    thumbnailUrl: string
    clipUrl: string
}


type SubjectsQueryParams = {
    categorySlug: string
}

type CategoryQueryParams = {
    categorySlug: string
}

type OwnProps = RouteComponentProps<SubjectsQueryParams>


type SubjectQueryParams = {
    subjectSlug: string
}

class ChooseExperienceViewGuestGuest extends React.Component<any, any> {
    state = {
        searchText: '',
        loadPage: false,
        openModal: false,
        currentShortText: ''
    }
    clientID = ''
    strDate: string | null | undefined
    strTime: string | null | undefined
    versionOs: any

    componentDidMount = async () => {

        let ipAddress = await publicIp.v4()

        if (ipAddress == null || ipAddress == undefined || ipAddress == '') {
            ipAddress = await publicIp.v6()
        }

        Mixpanel.identify(ipAddress)
        Mixpanel.people.set({ $name: ipAddress })
        Mixpanel.track(CHOOSE_EXPERIENCE_PAGE_LOADED, {
            [CLIENT_ID]: this.generateMixpanelValues(this.clientID),
            [EVENT_DATE]: this.generateMixpanelValues(this.strDate),
            [EVENT_TIME]: this.generateMixpanelValues(this.strTime),
            [FROM]: 'Website',
            [OS_VERSION]: this.generateMixpanelValues(this.versionOs),
        })

        // Check browser support

    }

    generateMixpanelValues(value: string | null | undefined) {
        if (
            value != null &&
            value != '' &&
            value != 'null' &&
            value != 'undefined' &&
            value != undefined
        )
            return value
        else return 'N/A'
    }

    detectOS = () => {
        var unknown = '-'

        // browser
        var nVer = navigator.appVersion
        var nAgt = navigator.userAgent

        // system
        var os = unknown
        var clientStrings = [
            { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Chrome OS', r: /CrOS/ },
            { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            {
                s: 'Search Bot',
                r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
            },
        ]
        for (var id in clientStrings) {
            var cs = clientStrings[id]
            if (cs.r.test(nAgt)) {
                os = cs.s
                break
            }
        }

        var osVersion: any = unknown

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)![1]
            os = 'Windows'
        }

        switch (os) {
            case 'Mac OS X':
                osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)![1]
                break

            case 'Android':
                osVersion = /Android ([\.\_\d]+)/.exec(nAgt)![1]
                break

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)!
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0)
                break
        }
        this.versionOs = os + ' ' + osVersion
    }

    //for open popup in mobile
    onOpenModel = (showText : any) => {
        console.log(showText);
        this.setState({
            openModal: true,
            currentShortText: showText
        });
    };

    //for close popup in mobile
    onCloseModal = () => {
        this.setState({ openModal: false });
    };

    render() {
        const { loading } = this.props.subjectsQuery && this.props.categoryQuery
        let { publicSubjects } = this.props.subjectsQuery
        const { category } = this.props.categoryQuery
        const { searchText } = this.state
        const { openModal } = this.state;
        let thumbs: Array<JSX.Element> = []
        if (publicSubjects && searchText) {
            publicSubjects = publicSubjects.filter((subject: Subject) => {
                return subject.metadata.includes(searchText)
            })
        }
        this.detectOS()

        const header = localStorage.getItem('jwt') ?  <CustomHeader /> :  <CustomHeaderForGuest />


        return (
            <>
                <div id="choose-experience-view">
                    <Helmet>
                        <title>{generatePageTitle('Choose an experience')}</title>
                    </Helmet>

                    <Responsive className="mobile-top-level-container" {...Responsive.onlyMobile}>
                        {header}
                        <Container className="main-container">
                            <Modal open={openModal} onClose={this.onCloseModal} center>
                                <div className="modalTextStyles">
                                    {this.state.currentShortText}
                                </div>
                            </Modal>
                            <Form loading={loading}>
                                {/* Searchbox commented as per the client request. Its not removing since this item will be needed in future */}
                                {/* <Input
                  className="search-box"
                  icon="search"
                  placeholder="Search..."
                  fluid
                  onChange={this.updateSearchText}
                  value={searchText}
                /> */}
                                <Grid>
                                    <Grid.Column>
                                        {this.props.match.params.categorySlug.toUpperCase() == 'COVID19' ? (
                                            <div className= "covid19TopTextStyles">
                                                <h4>{TOP_CONTAINER_HEADER_TEXT_FOR_COVID19}</h4>
                                                {TOP_CONTAINER_TEXT_FOR_COVID19}
                                                <a href='mailto:info@auranexus.com'>{AURANEXUS_INFO_MAIL_ID}</a>{'.'}
                                            </div>
                                        ) : null
                                        }
                                    </Grid.Column>
                                </Grid>
                                <Grid columns={COLUMNS_MOBILE} className="main-grid">
                                    {publicSubjects &&
                                    publicSubjects.map((subject: Subject, i: number) => (
                                        <Grid.Column className="image-col" key={i}>
                                            <Link to={`/public/categories/${this.props.match.params.categorySlug}/${subject.slug}`}>
                                                <Image className="subject-thumb" src={subject.thumbnailUrl} rounded />
                                                <p className="caption">{subject.displayName}</p>
                                            </Link>
                                            <div className="popupButtonDivStyles">
                                                <Icon name="info circle" className="popupButtonStyles" onClick={() => this.onOpenModel(subject.shortText)}/>
                                            </div>
                                        </Grid.Column>
                                    ))}
                                </Grid>
                            </Form>
                        </Container>
                        {this.props.match.params.categorySlug.toUpperCase() == 'COVID19' ? (
                            <Grid className="disclaimerDivMobile">
                                <div className="disclaimerTextStylesMobile">
                                    {DISCLAIMER_TEXT_COVID19}
                                </div>
                            </Grid>
                        ) : null
                        }
                    </Responsive>
                    <Responsive className="mobile-top-level-container" {...Responsive.onlyTablet}>
                        {header}
                        <Container className="main-container">
                            <Modal open={openModal} onClose={this.onCloseModal} center>
                                <div className="modalTextStyles">
                                    {this.state.currentShortText}
                                </div>
                            </Modal>
                            <Form loading={loading}>
                                {/* Searchbox commented as per the client request. Its not removing since this item will be needed in future */}
                                {/* <Input
                  className="search-box"
                  icon="search"
                  placeholder="Search..."
                  fluid
                  onChange={this.updateSearchText}
                  value={searchText}
                /> */}
                                <Grid>
                                    <Grid.Column>
                                        {this.props.match.params.categorySlug.toUpperCase() == 'COVID19' ? (
                                            <div className= "covid19TopTextStyles">
                                                <h4>{TOP_CONTAINER_HEADER_TEXT_FOR_COVID19}</h4>
                                                {TOP_CONTAINER_TEXT_FOR_COVID19}
                                                <a href='mailto:info@auranexus.com'>{AURANEXUS_INFO_MAIL_ID}</a>{'.'}
                                            </div>
                                        ) : null
                                        }
                                    </Grid.Column>
                                </Grid>
                                <Grid columns={COLUMNS_MOBILE} className="main-grid">
                                    {publicSubjects &&
                                    publicSubjects.map((subject: Subject, i: number) => (
                                        <Grid.Column className="image-col" key={i}>
                                            <Link to={`/public/categories/${this.props.match.params.categorySlug}/${subject.slug}`}>
                                                <Image className="subject-thumb" src={subject.thumbnailUrl} rounded />
                                                <p className="caption">{subject.displayName}</p>
                                            </Link>
                                            <div className="popupButtonDivStyles">
                                                <Icon name="info circle" className="popupButtonStyles" onClick={() => this.onOpenModel(subject.shortText)}/>
                                            </div>
                                        </Grid.Column>
                                    ))}
                                </Grid>
                            </Form>
                        </Container>
                        {this.props.match.params.categorySlug.toUpperCase() == 'COVID19' ? (
                            <Grid className="disclaimerDivMobile">
                                <div className="disclaimerTextStylesMobile">
                                    {DISCLAIMER_TEXT_COVID19}
                                </div>
                            </Grid>
                        ) : null
                        }
                    </Responsive>
                    <Responsive
                        as={Form}
                        loading={loading}
                        className="desktop-top-level-container"
                        {...Responsive.onlyComputer}
                    >
                        <Grid className="background-pattern">
                            <Grid.Row>
                                <Grid.Column width={5} className="gray-bar" />
                                <Grid.Column width={11} />
                            </Grid.Row>
                        </Grid>
                        <div className="main-content-holder">
                            {header}
                            <Container className="main-container">
                                <Grid relaxed>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <img className="left-bar-info" src={category && category.imageUrl} />
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <Grid>
                                                <Grid.Column>
                                                    {this.props.match.params.categorySlug.toUpperCase() == 'COVID19' ? (
                                                        <div className= "covid19TopTextStyles">
                                                            <h3>{TOP_CONTAINER_HEADER_TEXT_FOR_COVID19}</h3>
                                                            {TOP_CONTAINER_TEXT_FOR_COVID19}
                                                            <a href='mailto:info@auranexus.com'>{AURANEXUS_INFO_MAIL_ID}</a>{'.'}
                                                        </div>
                                                    ) : null
                                                    }
                                                </Grid.Column>
                                            </Grid>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={8} className="header-col">
                                                        <Header as="h1">Experiences</Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={8} textAlign="right">
                                                        {/* Searchbox commented as per the client request. Its not removing since this item will be needed in future */}
                                                        {/* <Input
                              className="search-box"
                              icon="search"
                              placeholder="Search..."
                              onChange={this.updateSearchText}
                              value={searchText}
                              size="big"
                            /> */}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <Divider />
                                            <Grid columns={COLUMNS_DESKTOP}>
                                                {publicSubjects &&
                                                publicSubjects.map((subject: Subject, i: number) => (
                                                    <Grid.Column className="image-col" key={i}>
                                                        <Popup
                                                            trigger={
                                                                <Link to={`/public/categories/${this.props.match.params.categorySlug}/${subject.slug}`}>
                                                                    <Image
                                                                        className="subject-thumb"
                                                                        src={subject.thumbnailUrl}
                                                                        rounded
                                                                    />
                                                                    <p className="caption">{subject.displayName}</p>
                                                                </Link>
                                                            }
                                                            content={subject.shortText}
                                                            position="bottom center"
                                                        />
                                                    </Grid.Column>
                                                ))}
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                        {this.props.match.params.categorySlug.toUpperCase() == 'COVID19' ? (
                            <Container>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={6} />
                                        <Grid.Column width={10}>
                                            <div className="disclaimerTextStyles">
                                                {DISCLAIMER_TEXT_COVID19}
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        ) : null
                        }
                        <Footer />
                    </Responsive>
                </div>
            </>
        )
    }
}

export default compose(

    graphql(subjectsQuery, {
        name: 'subjectsQuery',
        options: (props: OwnProps): QueryOpts<SubjectsQueryParams> => ({
            variables: {
                categorySlug: props.match.params.categorySlug,
            },
        }),
    }),

    graphql(categoryQuery, {
        name: 'categoryQuery',
        options: (props: OwnProps): QueryOpts<CategoryQueryParams> => ({
            variables: {
                categorySlug: props.match.params.categorySlug,
            },
        }),
    })
)(ChooseExperienceViewGuestGuest)
