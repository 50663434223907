import React from "react";
const publicIp = require("public-ip");
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { withApollo, graphql, compose, QueryOpts } from "react-apollo";
import gql from "graphql-tag";
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Image,
  Label,
  List,
  Responsive,
  Transition,
} from "semantic-ui-react";
import { BigPlayButton, ControlBar, Player } from "video-react";
import { RouteComponentProps } from "react-router";
import "video-react/dist/video-react.css";
import { ChildProps, ChildDataProps, MutateProps } from "react-apollo";

import { Mixpanel } from "src/mixpanel";
import Footer from "src/components/Footer";
import CustomHeader from "src/components/CustomHeader";
import { generatePageTitle } from "src/utils";
import playbutton from "src/images/whiteplay.png";
import logo from "src/images/Aura-Logo-v2-sans-text.png";
import "./style.css";
import "./style.scss";
import {
  VIEW_EXPERIENCE_PAGE_LOADED,
  DOMAIN,
  VIDEO_INTERRUPTED,
  QUESTION_ASKED,
  USERNAME,
  EMAIL,
  GENDER,
  DATE_JOINED,
  LAST_LOGIN,
  CLIENT_ID,
  USER_SESSION,
  FROM,
  VIDEO_NAME,
  CLIP_NAME,
  ASKED_QUESTION,
  RESPONSE_START,
  RESPONSE_END,
  RESPONSE_RECEIVED,
  TOTAL_SECONDS_WATCHED,
  TIME_TAKEN_TO_ASK_QUESTION,
  INTERRUPTED_TIME,
  EVENT_DATE,
  EVENT_TIME,
  VIDEO_INTERRUPTED_COUNT,
  RESPONSE_WATCHED,
  ORIGINAL_QUESTION,
  BORN_YEAR,
  OS_VERSION,
} from "src/constants";
import { isMobile } from "react-device-detect";

var recognizeMic = require("watson-speech/speech-to-text/recognize-microphone");

const speechMutation = gql`
  mutation fetchClip($transcribedText: String!, $subjectId: Int!) {
    fetchClip(transcribedText: $transcribedText, subjectId: $subjectId) {
      id
      dialogflowIntentName
      startTimestampInSeconds
      endTimestampInSeconds
    }
  }
`;

const subjectQuery = gql`
  query subject($subjectSlug: String!) {
    subject(subjectSlug: $subjectSlug) {
      id
      displayName
      slug
      gifStubUrl
      gifStubUrlMobile
      clipUrl
      clipUrlMobile
      thumbnailUrl
      shareable
      idleStartTimestampInSeconds
      idleEndTimestampInSeconds
      dontKnowStartTimestampInSeconds
      dontKnowEndTimestampInSeconds
      suggestions {
        content
        clip {
          id
        }
      }
      displayName
      bannerImgUrl
      aboutText
      introStartTimestampInSeconds
      introEndTimestampInSeconds
      category {
        slug
      }
    }
  }
`;

const watsonMutation = gql`
  mutation fetchWatsonToken {
    fetchWatsonToken {
      watsonAccessToken
    }
  }
`;

let introStart = 0;
let introEnd = 0;

const MODE_IDLE = "IDLE";
const MODE_ANSWER = "ANSWER";
const MODLE_DONT_KNOW = "DONT_KNOW";
const MODE_INTRO = "INTRO";

var userData: any;

//for timer
var timeBegan: any;
var timeStopped = null;
var stoppedDuration = 0;
var started: any;
var timerValue: any;
var timeElapsed: any;

//for mixpanel logging
var userSession: string | null | undefined;
var userIp = "";
var strDate: string | null | undefined;
var strTime: string | null | undefined;
var clientID = "";
var responseStartTime: any;
var responseEndTime: any;
var videoName = "";
var clipName = "";
var askedQuestion = "";
let originalQuestion: any;
var watchedTime: any;
var interruptedTime: any;
var interruptedCount: any;
var timeTakenToAsk: any;
var versionOs: any;
enum Modes {
  IDLE,
  ANSWER,
  DONT_KNOW,
  INTRO,
}

type SubjectQueryParams = {
  subjectSlug: string;
};

type Clip = {
  id: number;
};

type Suggestion = {
  content: string;
  clip: Clip;
};

type SubjectQueryResponse = {
  subject: {
    id: number;
    displayName: string;
    suggestions: Array<Suggestion>;
    clipUrl: string;
    clipUrlMobile: string;
    thumbnailUrl: string;
    shareable: boolean;
    gifStubUrl: string;
    gifStubUrlMobile: string;
    slug: string;
    bannerImgUrl: string;
    idleStartTimestampInSeconds: number;
    idleEndTimestampInSeconds: number;
    dontKnowStartTimestampInSeconds: number;
    dontKnowEndTimestampInSeconds: number;
    introStartTimestampInSeconds: number;
    introEndTimestampInSeconds: number;
    category: {
      slug: string;
    };
  };
};

type WatsonMutationResponse = {
  watsonAccessToken: string;
};

type FetchClipMutationParams = {
  base64String: string;
  sampleRate: number;
  subjectId: string;
};

type FetchClipMutationResponse = {
  id: number;
  transcribedQuery: String;
  dialogflowIntentName: String;
  startTimestampInSeconds: number;
  endTimestampInSeconds: number;
};

type OwnProps = RouteComponentProps<SubjectQueryParams>;
type SubjectQueryProps = ChildDataProps<
  SubjectQueryParams,
  SubjectQueryResponse
> & {
  speechMutation: Function;
  watsonMutation: Function;
};
type Props = SubjectQueryProps & OwnProps;

declare global {
  interface Window {
    AudioContext: any;
    webkitAudioContext: any;
    MSStream: any;
  }
}

type User = {
  date_joined: string;
  email: string;
  first_name: string;
  gender: null | string;
  is_active: boolean;
  last_login: string;
  last_name: string;
  year_of_birth: null | string;
};

type State = {
  currentClip: FetchClipMutationResponse | null | undefined;
  record: boolean;
  attemptMade: boolean;
  videoStartTime: number | null | undefined;
  mode: Modes;
  visible: boolean;
  error: boolean;
  processing: boolean;
  watsonAccessToken: string;
  showTranscribedTextHeader: boolean;
  seenClipsById: Array<number>;
  textboxVisible: boolean;
  isLoading: boolean;
  isLoadingMobile: boolean;
  pausedState: boolean;
  currentVideoTime: number | null | undefined;
  time: number;
  isOn: boolean;
  start: number;
  timer: any;
  recognizedText: any;
  loadPage: boolean;
  onPlay: boolean;
  isResponseReceived: string;
  userData: User | null;
};

const detectOS = () => {
  var unknown = "-";

  // browser
  var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;

  // system
  var os = unknown;
  var clientStrings = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Chrome OS", r: /CrOS/ },
    { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (var id in clientStrings) {
    var cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  var osVersion: any = unknown;

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)![1];
    os = "Windows";
  }

  switch (os) {
    case "Mac OS X":
      osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)![1];
      break;

    case "Android":
      osVersion = /Android ([\.\_\d]+)/.exec(nAgt)![1];
      break;

    case "iOS":
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)!;
      osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
      break;
  }
  versionOs = os + " " + osVersion;
};

class ViewExperienceView extends React.Component<Props, State> {
  refs: any;

  state: State = {
    currentClip: null,
    record: false,
    videoStartTime: null,
    mode: Modes.IDLE,
    visible: true,
    error: false,
    processing: false,
    attemptMade: false,
    watsonAccessToken: "",
    showTranscribedTextHeader: false,
    seenClipsById: [],
    textboxVisible: false,
    isLoading: false,
    isLoadingMobile: false,
    pausedState: false,
    currentVideoTime: null,
    time: 0,
    isOn: false,
    start: 0,
    timer: "",
    recognizedText: "",
    loadPage: false,
    onPlay: false,
    isResponseReceived: "",
    userData: null,
  };

  componentWillUnmount = () => {
    this.setState({ onPlay: false });
  };

  componentDidMount = async () => {
    this.setState({ onPlay: true });
    if (localStorage.getItem("jwt") || localStorage.getItem("cat")) {
      if (localStorage.getItem("cat") != null) {
        var categoriesList = localStorage.getItem("cat");
        if (categoriesList != null) {
          var categoriesArray = categoriesList.split(",");
          var urlLocation = window.location.pathname;
          categoriesArray.forEach((category) => {
            if (urlLocation.includes(category)) {
              this.setState({ loadPage: true });
            }
          });
        }
      }
    }

    let ipAddress = await publicIp.v4();

    if (ipAddress == null || ipAddress == undefined || ipAddress == "") {
      ipAddress = await publicIp.v6();
    }

    var date = new Date();
    this.formatDate(date);
    userSession = sessionStorage.getItem("User-session");
    if (this.state.loadPage && this.state.onPlay && this.refs.player) {
      this.refs.player.subscribeToStateChange(this.handleIdlePlayerStateChange);
    }

    let { data } = await this.props.watsonMutation();
    // setUserData(data);userdatan tanel state vor mixpaneli mej userdataic error chqci. u test anel

    userData = data.user; // TODO: keep in state
    if (!userData) {
      return;
    }

    this.setState({
      userData: data.user,
    });

    var mainData = {
      [USERNAME]: this.generateMixpanelValues(
        userData.first_name + " " + userData.last_name
      ),
      [EMAIL]: this.generateMixpanelValues(userData.email),
      [GENDER]: this.generateMixpanelValues(userData.gender),
      [BORN_YEAR]: this.generateMixpanelValues(userData.year_of_birth),
      [DATE_JOINED]: this.generateMixpanelValues(userData.date_joined),
      [LAST_LOGIN]: this.generateMixpanelValues(userData.last_login),
      [CLIENT_ID]: this.generateMixpanelValues(clientID),
      [USER_SESSION]: this.generateMixpanelValues(userSession),
      [EVENT_DATE]: this.generateMixpanelValues(strDate),
      [EVENT_TIME]: this.generateMixpanelValues(strTime),
      [FROM]: "Website",
      [VIDEO_NAME]: this.generateMixpanelValues(videoName),
      [CLIP_NAME]: this.generateMixpanelValues(""),
      [ASKED_QUESTION]: this.generateMixpanelValues(""),
      [RESPONSE_START]: this.generateMixpanelValues(""),
      [RESPONSE_END]: this.generateMixpanelValues(""),
      [RESPONSE_RECEIVED]: this.generateMixpanelValues(""),
      [TOTAL_SECONDS_WATCHED]: this.generateMixpanelValues(""),
      [TIME_TAKEN_TO_ASK_QUESTION]: this.generateMixpanelValues(""),
      [INTERRUPTED_TIME]: this.generateMixpanelValues(""),
      [EVENT_DATE]: this.generateMixpanelValues(strDate),
      [EVENT_TIME]: this.generateMixpanelValues(strTime),
      [VIDEO_INTERRUPTED_COUNT]: this.generateMixpanelValues(""),
      [ORIGINAL_QUESTION]: this.generateMixpanelValues(""),
      [OS_VERSION]: this.generateMixpanelValues(versionOs),
    };

    Mixpanel.identify(ipAddress);
    Mixpanel.people.set({ $name: ipAddress });
    Mixpanel.people.increment("Total_view");
    Mixpanel.track(VIEW_EXPERIENCE_PAGE_LOADED, mainData);
    this.setState({
      watsonAccessToken: data.fetchWatsonToken.watsonAccessToken,
    });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const { subject } = this.props.data;
    if (
      (subject &&
      subject.clipUrlMobile &&
      this.state.isLoadingMobile === false) || (subject && subject.clipUrl && this.state.isLoading ===false)) {
     
      this.setState({
        isLoading: true,
        isLoadingMobile: true,
      });
    }
  }
  //exclude undefined and null values
  generateMixpanelValues(value: string | null | undefined) {
    if (
      value != null &&
      value != "" &&
      value != "null" &&
      value != "undefined" &&
      value != undefined
    )
      return value;
    else return "N/A";
  }

  //Timer Start
  startTimer() {
    this.setState({
      isOn: true,
      time: 0,
      start: Date.now() - this.state.time,
    });

    this.state.timer = setInterval(
      () =>
        this.setState({
          time: Date.now() - this.state.start,
        }),
      1
    );
  }

  stopTimer() {
    this.setState({ isOn: false });
    clearInterval(this.state.timer);
  }

  resetTimer() {
    this.setState({ time: 0, isOn: false });
  }
  //Timer code ends

  //formating date time
  formatDate(date: any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    // strDate = (month > 9 ? month : "0" + month) + "-" + (day > 9 ? day : "0" + day) + "-" + year;
    strDate = month + "-" + day + "-" + year;
    strTime =
      (hours > 9 ? hours : "0" + hours) +
      ":" +
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds);
    return (
      date.getMonth() +
      1 +
      "-" +
      date.getDate() +
      "-" +
      date.getFullYear() +
      "-" +
      strTime
    );
  }

  msToTime(s: any) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var seconds = s % 60;
    s = (s - seconds) / 60;
    var minutes = s % 60;
    var hours = (s - minutes) / 60;

    return (
      (hours > 9 ? hours : "0" + hours) +
      ":" +
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds)
    );
  }

  startIntro = () => {
    const { subject } = this.props.data;
    const { mode, currentClip, record } = this.state;

    introStart = 0;
    introEnd = 0;
    interruptedCount = 0;
    if (subject) {
      introStart = subject.introStartTimestampInSeconds;
      introEnd = subject.introEndTimestampInSeconds;
    }

    this.setState({
      videoStartTime: introStart,
      textboxVisible: true,
      mode: Modes.INTRO,
      error: false,
      processing: false,
      attemptMade: true,
    });

    clipName = "INTRO";

    if (introStart != null && introEnd != null) {
      let time = introEnd - introStart;
      Mixpanel.people.increment("Total_seconds", time);
    }

    this.refs.player && this.refs.player.seek(subject && introStart);
    this.refs.player && this.refs.player.play();
  };

  start = () => {
    const { attemptMade, seenClipsById } = this.state;
    const { subject } = this.props.data;
    this.startTimer();
    this.setState({
      videoStartTime: subject && subject.idleStartTimestampInSeconds + 5,
      mode: Modes.IDLE,
    });

    var date = new Date();
    this.formatDate(date);

    if (this.state.currentVideoTime != null) {
      if (
        (subject &&
          this.state.currentVideoTime < subject.idleStartTimestampInSeconds &&
          this.state.currentVideoTime > subject.idleEndTimestampInSeconds) ||
        (subject &&
          this.state.currentVideoTime < subject.idleStartTimestampInSeconds &&
          this.state.currentVideoTime < subject.idleEndTimestampInSeconds) ||
        (subject &&
          this.state.currentVideoTime > subject.idleStartTimestampInSeconds &&
          this.state.currentVideoTime > subject.idleEndTimestampInSeconds)
      ) {
        Mixpanel.people.increment("Video interruption count");
        if (clipName == "INTRO") {
          this.state.isResponseReceived = "N/A";
          responseStartTime = introStart;
          responseEndTime = introEnd;
        } else {
          this.state.isResponseReceived = "Yes";
        }
        if (responseStartTime == 0) {
          responseStartTime = "0";
        }
        interruptedTime = parseInt(this.state.currentVideoTime.toString());
        interruptedCount++;

        Mixpanel.track(VIDEO_INTERRUPTED, {
          [USERNAME]: this.generateMixpanelValues(
            userData.first_name + " " + userData.last_name
          ),
          [EMAIL]: this.generateMixpanelValues(userData.email),
          [GENDER]: this.generateMixpanelValues(userData.gender),
          [BORN_YEAR]: this.generateMixpanelValues(userData.year_of_birth),
          [DATE_JOINED]: this.generateMixpanelValues(userData.date_joined),
          [LAST_LOGIN]: this.generateMixpanelValues(userData.last_login),
          [CLIENT_ID]: this.generateMixpanelValues(clientID),
          [USER_SESSION]: this.generateMixpanelValues(userSession),
          [FROM]: "Website",
          [VIDEO_NAME]: this.generateMixpanelValues(videoName),
          [CLIP_NAME]: this.generateMixpanelValues(clipName),
          [ASKED_QUESTION]: this.generateMixpanelValues(askedQuestion),
          [RESPONSE_START]: this.generateMixpanelValues(responseStartTime),
          [RESPONSE_END]: this.generateMixpanelValues(responseEndTime),
          [TOTAL_SECONDS_WATCHED]: this.generateMixpanelValues(""),
          [TIME_TAKEN_TO_ASK_QUESTION]: this.generateMixpanelValues(""),
          [RESPONSE_RECEIVED]: this.generateMixpanelValues(
            this.state.isResponseReceived
          ),
          [INTERRUPTED_TIME]: this.generateMixpanelValues(interruptedTime),
          [EVENT_DATE]: this.generateMixpanelValues(strDate),
          [EVENT_TIME]: this.generateMixpanelValues(strTime),
          [VIDEO_INTERRUPTED_COUNT]: this.generateMixpanelValues("1"),
          [ORIGINAL_QUESTION]: this.generateMixpanelValues(originalQuestion),
          [OS_VERSION]: this.generateMixpanelValues(versionOs),
        });
      }
    }

    Mixpanel.people.increment("Total_Questions_Asked");
    this.refs.player.seek(subject && subject.idleStartTimestampInSeconds + 5);

    // iOS compromise
    if (!attemptMade) {
      this.refs.player && this.refs.player.play();
      this.refs.player && this.refs.player.pause();
    }

    this.setState({
      showTranscribedTextHeader: true,
      record: true,
      attemptMade: true,
      error: false,
      recognizedText: "",
    });

    var stream = recognizeMic({
      //access_token: this.state.watsonAccessToken,
      accessToken:  this.state.watsonAccessToken,
      outputElement: "#output",
      language_customization_id: "923757ac-136f-47b8-9aed-b08a58b27e2f",
    });

    stream.on("error", function (err: string) {
      console.log(err);
    });

    stream.on("data", async (data: any) => {
      this.resetTimer();

  
      this.state.recognizedText = data.results[0].alternatives[0].transcript;
      this.setState({
        recognizedText:data.results[0].alternatives[0].transcript 
      })
      if (data.results[0] && data.results[0].final) {
        stream.stop();
        this.setState({ record: false, processing: true });
        const { subject } = this.props.data;
        let res = await this.props.speechMutation({
          variables: {
            subjectId: subject && subject.id,
            transcribedText: data.results[0].alternatives[0].transcript,
          },
        });
     
        interruptedCount = 0;
        this.stopTimer();
        timeTakenToAsk = this.msToTime(this.state.time);
        this.resetTimer();

        askedQuestion = data.results[0].alternatives[0].transcript;
        if (
          res.data.fetchClip.endTimestampInSeconds != null &&
          res.data.fetchClip.startTimestampInSeconds != null
        ) {
          let time =
            res.data.fetchClip.endTimestampInSeconds -
            res.data.fetchClip.startTimestampInSeconds;
          Mixpanel.people.increment("Total_seconds", time);
        }

        var date = new Date();
        this.formatDate(date);

        clipName = res.data.fetchClip.dialogflowIntentName;
        if (
          res.data.fetchClip &&
          res.data.fetchClip.dialogflowIntentName === "ERROR"
        ) {
          if (
            subject &&
            (!subject.dontKnowStartTimestampInSeconds ||
              !subject.dontKnowEndTimestampInSeconds)
          ) {
            this.setState({
              mode: Modes.IDLE,
              error: true,
              processing: false,
            });

            var date = new Date();
            this.formatDate(date);

            if (subject) {
              responseStartTime = res.data.fetchClip.startTimestampInSeconds;
              responseEndTime = res.data.fetchClip.endTimestampInSeconds;
              Mixpanel.track(QUESTION_ASKED, {
                [USERNAME]: this.generateMixpanelValues(
                  userData.first_name + " " + userData.last_name
                ),
                [EMAIL]: this.generateMixpanelValues(userData.email),
                [GENDER]: this.generateMixpanelValues(userData.gender),
                [BORN_YEAR]: this.generateMixpanelValues(
                  userData.year_of_birth
                ),
                [DATE_JOINED]: this.generateMixpanelValues(
                  userData.date_joined
                ),
                [LAST_LOGIN]: this.generateMixpanelValues(userData.last_login),
                [CLIENT_ID]: this.generateMixpanelValues(clientID),
                [USER_SESSION]: this.generateMixpanelValues(userSession),
                [FROM]: "Website",
                [VIDEO_NAME]: this.generateMixpanelValues(videoName),
                [CLIP_NAME]: this.generateMixpanelValues(clipName),
                [ASKED_QUESTION]: this.generateMixpanelValues(askedQuestion),
                [RESPONSE_START]: this.generateMixpanelValues(
                  responseStartTime
                ),
                [RESPONSE_END]: this.generateMixpanelValues(responseEndTime),
                [RESPONSE_RECEIVED]: this.generateMixpanelValues("No"),
                [TOTAL_SECONDS_WATCHED]: this.generateMixpanelValues(""),
                [TIME_TAKEN_TO_ASK_QUESTION]: this.generateMixpanelValues(
                  timeTakenToAsk
                ),
                [INTERRUPTED_TIME]: this.generateMixpanelValues(""),
                [EVENT_DATE]: this.generateMixpanelValues(strDate),
                [EVENT_TIME]: this.generateMixpanelValues(strTime),
                [VIDEO_INTERRUPTED_COUNT]: this.generateMixpanelValues(""),
                [ORIGINAL_QUESTION]: this.generateMixpanelValues(
                  originalQuestion
                ),
                [OS_VERSION]: this.generateMixpanelValues(versionOs),
              });
            }
        
            //IOS ONLY
            var isIOS =
              /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (isIOS) {
           
              this.refs.player && this.refs.player.seek(
                subject && subject.idleStartTimestampInSeconds + 10
              );
              this.refs.player && this.refs.player.play();
            }
          } else {
            this.setState({
              mode: Modes.DONT_KNOW,
              error: true,
              processing: false,
            });

            if (subject) {
              responseStartTime = subject.dontKnowStartTimestampInSeconds;
              responseEndTime = subject.dontKnowEndTimestampInSeconds;
              Mixpanel.track(QUESTION_ASKED, {
                [USERNAME]: this.generateMixpanelValues(
                  userData.first_name + " " + userData.last_name
                ),
                [EMAIL]: this.generateMixpanelValues(userData.email),
                [GENDER]: this.generateMixpanelValues(userData.gender),
                [BORN_YEAR]: this.generateMixpanelValues(
                  userData.year_of_birth
                ),
                [DATE_JOINED]: this.generateMixpanelValues(
                  userData.date_joined
                ),
                [LAST_LOGIN]: this.generateMixpanelValues(userData.last_login),
                [CLIENT_ID]: this.generateMixpanelValues(clientID),
                [USER_SESSION]: this.generateMixpanelValues(userSession),
                [FROM]: "Website",
                [VIDEO_NAME]: this.generateMixpanelValues(videoName),
                [CLIP_NAME]: this.generateMixpanelValues(clipName),
                [ASKED_QUESTION]: this.generateMixpanelValues(askedQuestion),
                [RESPONSE_START]: this.generateMixpanelValues(
                  responseStartTime
                ),
                [RESPONSE_END]: this.generateMixpanelValues(responseEndTime),
                [RESPONSE_RECEIVED]: this.generateMixpanelValues("No"),
                [TOTAL_SECONDS_WATCHED]: this.generateMixpanelValues(""),
                [TIME_TAKEN_TO_ASK_QUESTION]: this.generateMixpanelValues(
                  timeTakenToAsk
                ),
                [INTERRUPTED_TIME]: this.generateMixpanelValues(""),
                [EVENT_DATE]: this.generateMixpanelValues(strDate),
                [EVENT_TIME]: this.generateMixpanelValues(strTime),
                [VIDEO_INTERRUPTED_COUNT]: this.generateMixpanelValues(""),
                [ORIGINAL_QUESTION]: this.generateMixpanelValues(
                  originalQuestion
                ),
                [OS_VERSION]: this.generateMixpanelValues(versionOs),
              });
            }
       
           // IOS ONLY
            this.refs.player &&
              this.refs.player.seek(
                subject && subject.dontKnowStartTimestampInSeconds
              );
              this.refs.player && this.refs.player.play();
          }
        } else {
          this.setState({
            currentClip: res.data.fetchClip,
            videoStartTime:
              res.data.fetchClip && res.data.fetchClip.startTimestampInSeconds,
            mode: Modes.ANSWER,
            processing: false,
            seenClipsById: [...seenClipsById, res.data.fetchClip.id],
          });
  
          originalQuestion = "";
          this.refs.player && this.refs.player.seek(
            res.data.fetchClip && res.data.fetchClip.startTimestampInSeconds
          );
    
          // If the player was paused, this is needed
          this.refs.player && this.refs.player.play();

          if (subject) {
            subject.suggestions.map((suggestion, index) => {
              if (res.data.fetchClip.id == suggestion.clip.id) {
                originalQuestion = suggestion.content;
              }
            });

            responseStartTime = res.data.fetchClip.startTimestampInSeconds;
            responseEndTime = res.data.fetchClip.endTimestampInSeconds;
            this.state.isResponseReceived = "Yes";
            Mixpanel.track(QUESTION_ASKED, {
              [USERNAME]: this.generateMixpanelValues(
                userData.first_name + " " + userData.last_name
              ),
              [EMAIL]: this.generateMixpanelValues(userData.email),
              [GENDER]: this.generateMixpanelValues(userData.gender),
              [BORN_YEAR]: this.generateMixpanelValues(userData.year_of_birth),
              [DATE_JOINED]: this.generateMixpanelValues(userData.date_joined),
              [LAST_LOGIN]: this.generateMixpanelValues(userData.last_login),
              [CLIENT_ID]: this.generateMixpanelValues(clientID),
              [USER_SESSION]: this.generateMixpanelValues(userSession),
              [FROM]: "Website",
              [VIDEO_NAME]: this.generateMixpanelValues(videoName),
              [CLIP_NAME]: this.generateMixpanelValues(clipName),
              [ASKED_QUESTION]: this.generateMixpanelValues(askedQuestion),
              [RESPONSE_START]: this.generateMixpanelValues(responseStartTime),
              [RESPONSE_END]: this.generateMixpanelValues(responseEndTime),
              [RESPONSE_RECEIVED]: this.generateMixpanelValues("Yes"),
              [TOTAL_SECONDS_WATCHED]: this.generateMixpanelValues(""),
              [TIME_TAKEN_TO_ASK_QUESTION]: this.generateMixpanelValues(
                timeTakenToAsk
              ),
              [INTERRUPTED_TIME]: this.generateMixpanelValues(""),
              [EVENT_DATE]: this.generateMixpanelValues(strDate),
              [EVENT_TIME]: this.generateMixpanelValues(strTime),
              [VIDEO_INTERRUPTED_COUNT]: this.generateMixpanelValues(""),
              [ORIGINAL_QUESTION]: this.generateMixpanelValues(
                originalQuestion
              ),
              [OS_VERSION]: this.generateMixpanelValues(versionOs),
            });
          }
        }
      }
    });
  };

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    if (!newProps.data.loading && newProps.data.subject) {
      return {
        videoStartTime: newProps.data.subject.idleStartTimestampInSeconds + 5,
      };
    }

    return prevState;
  }

  // Wait until the idle clip ends and then loop
  handleIdlePlayerStateChange = (state: any, prevState: any) => {
    const { subject } = this.props.data;
    const { mode, currentClip, record } = this.state;
    this.setState({ currentVideoTime: state.currentTime });
    // console.log("currenttimje-------------", state.currentTime);
    var date = new Date();
    this.formatDate(date);

    if (currentClip != null || currentClip != undefined) {
      watchedTime =
        parseInt(state.currentTime) - currentClip.startTimestampInSeconds;
    }

    interruptedTime = parseInt(state.currentTime);

    if (prevState.paused != state.paused && state.paused == true) {
      interruptedCount++;
      Mixpanel.people.increment("Video interruption count");
      if (clipName == "INTRO") {
        this.state.isResponseReceived = "N/A";
        responseStartTime = introStart;
        responseEndTime = introEnd;
      } else {
        this.state.isResponseReceived = "Yes";
      }
      if (responseStartTime == 0) {
        responseStartTime = "0";
      }
      Mixpanel.track(VIDEO_INTERRUPTED, {
        [USERNAME]: this.generateMixpanelValues(
          userData.first_name + " " + userData.last_name
        ),
        [EMAIL]: this.generateMixpanelValues(userData.email),
        [GENDER]: this.generateMixpanelValues(userData.gender),
        [BORN_YEAR]: this.generateMixpanelValues(userData.year_of_birth),
        [DATE_JOINED]: this.generateMixpanelValues(userData.date_joined),
        [LAST_LOGIN]: this.generateMixpanelValues(userData.last_login),
        [CLIENT_ID]: this.generateMixpanelValues(clientID),
        [USER_SESSION]: this.generateMixpanelValues(userSession),
        [FROM]: "Website",
        [VIDEO_NAME]: this.generateMixpanelValues(videoName),
        [CLIP_NAME]: this.generateMixpanelValues(clipName),
        [ASKED_QUESTION]: this.generateMixpanelValues(askedQuestion),
        [RESPONSE_START]: this.generateMixpanelValues(responseStartTime),
        [RESPONSE_END]: this.generateMixpanelValues(responseEndTime),
        [TOTAL_SECONDS_WATCHED]: this.generateMixpanelValues(""),
        [TIME_TAKEN_TO_ASK_QUESTION]: this.generateMixpanelValues(""),
        [RESPONSE_RECEIVED]: this.generateMixpanelValues(
          this.state.isResponseReceived
        ),
        [INTERRUPTED_TIME]: this.generateMixpanelValues(interruptedTime),
        [EVENT_DATE]: this.generateMixpanelValues(strDate),
        [EVENT_TIME]: this.generateMixpanelValues(strTime),
        [VIDEO_INTERRUPTED_COUNT]: this.generateMixpanelValues("1"),
        [ORIGINAL_QUESTION]: this.generateMixpanelValues(originalQuestion),
        [OS_VERSION]: this.generateMixpanelValues(versionOs),
      });
    }

    if (
      mode === Modes.IDLE &&
      subject &&
      parseInt(state.currentTime) >= subject.idleEndTimestampInSeconds
    ) {
      if (
        subject.idleEndTimestampInSeconds != null &&
        subject.idleStartTimestampInSeconds != null
      ) {
        let time =
          subject.idleEndTimestampInSeconds -
          subject.idleStartTimestampInSeconds;
        Mixpanel.people.increment("Total_seconds", time);
      }
            //repeat
      this.refs.player && this.refs.player.seek(subject.idleStartTimestampInSeconds + 5);
    } else if (
      mode === Modes.INTRO &&
      subject &&
      parseInt(state.currentTime) >= introEnd
    ) {
      this.setState({
        videoStartTime: subject && subject.idleStartTimestampInSeconds + 5,
        mode: Modes.IDLE,
      });
    
      this.refs.player && this.refs.player.seek(subject && subject.idleStartTimestampInSeconds + 5);
      clipName = "INTRO";
      watchedTime = parseInt(state.currentTime) - introStart;
      responseStartTime = introStart;
      responseEndTime = introEnd;
   
      if (clipName == "INTRO") {
        this.state.isResponseReceived = "N/A";
      } else {
        this.state.isResponseReceived = "Yes";
      }
      Mixpanel.track(RESPONSE_WATCHED, {
        [USERNAME]: this.generateMixpanelValues(
          userData.first_name + " " + userData.last_name
        ),
        [EMAIL]: this.generateMixpanelValues(userData.email),
        [GENDER]: this.generateMixpanelValues(userData.gender),
        [BORN_YEAR]: this.generateMixpanelValues(userData.year_of_birth),
        [DATE_JOINED]: this.generateMixpanelValues(userData.date_joined),
        [LAST_LOGIN]: this.generateMixpanelValues(userData.last_login),
        [CLIENT_ID]: this.generateMixpanelValues(clientID),
        [USER_SESSION]: this.generateMixpanelValues(userSession),
        [FROM]: "Website",
        [VIDEO_NAME]: this.generateMixpanelValues(videoName),
        [CLIP_NAME]: this.generateMixpanelValues(clipName),
        [ASKED_QUESTION]: this.generateMixpanelValues(askedQuestion),
        [RESPONSE_START]: this.generateMixpanelValues(responseStartTime),
        [RESPONSE_END]: this.generateMixpanelValues(responseEndTime),
        [RESPONSE_RECEIVED]: this.generateMixpanelValues(
          this.state.isResponseReceived
        ),
        [TOTAL_SECONDS_WATCHED]: this.generateMixpanelValues(watchedTime),
        [TIME_TAKEN_TO_ASK_QUESTION]: this.generateMixpanelValues(""),
        [INTERRUPTED_TIME]: this.generateMixpanelValues(""),
        [EVENT_DATE]: this.generateMixpanelValues(strDate),
        [EVENT_TIME]: this.generateMixpanelValues(strTime),
        [VIDEO_INTERRUPTED_COUNT]: this.generateMixpanelValues(
          interruptedCount
        ),
        [ORIGINAL_QUESTION]: this.generateMixpanelValues(originalQuestion),
        [OS_VERSION]: this.generateMixpanelValues(versionOs),
      });
    } else if (
      mode === Modes.ANSWER &&
      currentClip &&
      parseInt(state.currentTime) >= currentClip.endTimestampInSeconds
    ) {
   
      this.setState({
        videoStartTime: subject && subject.idleStartTimestampInSeconds + 5,
        mode: Modes.IDLE,
      });
    
      this.refs.player && this.refs.player.seek(subject && subject.idleStartTimestampInSeconds + 5);

      Mixpanel.track(RESPONSE_WATCHED, {
        [USERNAME]: this.generateMixpanelValues(
          userData.first_name + " " + userData.last_name
        ),
        [EMAIL]: this.generateMixpanelValues(userData.email),
        [GENDER]: this.generateMixpanelValues(userData.gender),
        [BORN_YEAR]: this.generateMixpanelValues(userData.year_of_birth),
        [DATE_JOINED]: this.generateMixpanelValues(userData.date_joined),
        [LAST_LOGIN]: this.generateMixpanelValues(userData.last_login),
        [CLIENT_ID]: this.generateMixpanelValues(clientID),
        [USER_SESSION]: this.generateMixpanelValues(userSession),
        [FROM]: "Website",
        [VIDEO_NAME]: this.generateMixpanelValues(videoName),
        [CLIP_NAME]: this.generateMixpanelValues(clipName),
        [ASKED_QUESTION]: this.generateMixpanelValues(askedQuestion),
        [RESPONSE_START]: this.generateMixpanelValues(responseStartTime),
        [RESPONSE_END]: this.generateMixpanelValues(responseEndTime),
        [RESPONSE_RECEIVED]: this.generateMixpanelValues("Yes"),
        [TOTAL_SECONDS_WATCHED]: this.generateMixpanelValues(watchedTime),
        [TIME_TAKEN_TO_ASK_QUESTION]: this.generateMixpanelValues(""),
        [INTERRUPTED_TIME]: this.generateMixpanelValues(""),
        [EVENT_DATE]: this.generateMixpanelValues(strDate),
        [EVENT_TIME]: this.generateMixpanelValues(strTime),
        [VIDEO_INTERRUPTED_COUNT]: this.generateMixpanelValues(
          interruptedCount
        ),
        [ORIGINAL_QUESTION]: this.generateMixpanelValues(originalQuestion),
        [OS_VERSION]: this.generateMixpanelValues(versionOs),
      });
    } else if (mode === Modes.ANSWER && record) {
      this.refs.player.pause();
    } else if (
      mode === Modes.DONT_KNOW &&
      subject &&
      parseInt(state.currentTime) >= subject.dontKnowEndTimestampInSeconds
    ) {
      this.setState({
        videoStartTime: subject && subject.idleStartTimestampInSeconds + 5,
        mode: Modes.IDLE,
      });
      if (
        subject.dontKnowStartTimestampInSeconds != null &&
        subject.dontKnowEndTimestampInSeconds != null
      ) {
        let time =
          subject.dontKnowEndTimestampInSeconds -
          subject.dontKnowStartTimestampInSeconds;
        Mixpanel.people.increment("Total_seconds", time);
      }
   
      this.refs.player && this.refs.player.seek(subject && subject.idleStartTimestampInSeconds + 5);
    }
  };

  toggleRecording = () => {
    if (this.state.record) {
    }
    this.setState({
      record: !this.state.record,
      visible: false,
    });
  };

  onHide = () => {
    this.setState({ visible: true });
  };

  onShow = () => {
    if (this.state.record) this.setState({ visible: false });
  };

  shareTwitter = (slug: string) => {
    window.open(
      `https://twitter.com/home?status=Check out ${slug} on AuraNexus: ${DOMAIN}/${slug}`,
      "_blank"
    );
  };

  shareFacebook = (slug: string) => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${DOMAIN}/${slug}`,
      "_blank"
    );
  };

  render() {
    const {
      showTranscribedTextHeader,
      currentClip,
      attemptMade,
      videoStartTime,
      record,
      mode,
      error,
      processing,
      visible,
      seenClipsById,
    } = this.state;
    const { subject, loading } = this.props.data;
    videoName = "";
    if (subject) {
      videoName = subject.displayName;
    }

    let statusText = null;

    if (error) {
      statusText = "That didn't work, try again.";
    } else if (processing) {
      statusText = "Processing...";
    } else if (record) {
      statusText = "Start speaking into the mic";
      if (this.state.recognizedText != "") {
        statusText = this.state.recognizedText;
      }
    } else if (!attemptMade && mode === Modes.IDLE && !isMobile) {
      statusText = "Tap the button to ask a question.";
    } else if (mode === Modes.ANSWER || mode === Modes.IDLE) {
      statusText = "Tap the button to ask another question.";
    }

    detectOS();

    return (
      <>
        {this.state.loadPage && this.state.onPlay ? (
          <div id="view-experience-view">
            <Helmet>
              <title>{generatePageTitle("View experience")}</title>
            </Helmet>
            <Responsive {...Responsive.onlyMobile} className="mobile">
              <CustomHeader title={subject && subject.displayName} />
              <Form loading={isMobile && !this.state.isLoadingMobile}>
                {subject && subject.bannerImgUrl && (
                  <>
                    <img className="banner" src={subject.bannerImgUrl} />
                    <br />
                  </>
                )}
                <Responsive
                  className={!attemptMade ? "mobileDivHt" : "mobileDivHTUnset"}
                >
                  {subject && (
                    <p className={"videoWrapper"}>
                      <Image
                        disabled={!this.state.isLoadingMobile}
                        src={subject.thumbnailUrl}
                        className={"gif-stub " + (attemptMade ? "hidden" : "")}
                        onClick={() => {
                          this.state.isLoadingMobile ? this.startIntro() : "";
                        }}
                      />
                      <Image
                        disabled={!this.state.isLoadingMobile}
                        size="mini"
                        className={
                          "playbutton " + (attemptMade ? "hidden" : "")
                        }
                        src={playbutton}
                        onClick={() => {
                          this.state.isLoadingMobile ? this.startIntro() : "";
                        }}
                      />
                    </p>
                  )}

                  <div>
                    <Player
                      ref="player"
                      playsInline
                      className={attemptMade ? "" : "vis-hidden"}
                      startTime={videoStartTime}
                      fluid={true}
                      src={
                        subject &&
                        (subject.clipUrlMobile
                          ? subject.clipUrlMobile
                          : subject.clipUrl)
                      }
                      onLoadStart={() => {
                        this.setState({
                          isLoadingMobile: false,
                        });
                      }}
                      onLoadedMetadata={() => {
                        this.setState({
                          isLoadingMobile: true,
                        });
                      }}
                      onLoadedData={() => {
                        if (attemptMade) {
                          this.startIntro();
                        }
                      }}
                    >
                      <BigPlayButton
                        position="center"
                        className="big-play-button-hide"
                      />
                      <ControlBar disableCompletely={true} />
                    </Player>
                    {!this.state.isLoadingMobile && (
                      <div className="loadingMobileTextStyles">
                        Experience Loading...
                      </div>
                    )}
                  </div>
                </Responsive>
                <Container
                  className={
                    !this.state.textboxVisible ? "hidden" : "main-container"
                  }
                >
                  {!attemptMade && (
                    <Button
                      color="red"
                      size="large"
                      className="record-btn"
                      icon
                      fluid
                      labelPosition="left"
                      disabled={record}
                      onClick={this.start}
                    >
                      <Icon name="microphone" />
                      Tap to ask a question
                    </Button>
                  )}
                  <Grid
                    columns={2}
                    className={
                      !this.state.textboxVisible
                        ? "hidden"
                        : !attemptMade
                        ? "hidden"
                        : ""
                    }
                  >
                    <Grid.Row>
                      <Grid.Column width={12} className="left-col">
                        <Input
                          id="output"
                          fluid
                          disabled
                          value={statusText ? statusText : ""}
                        />
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Button
                          className="record-btn"
                          icon
                          fluid
                          disabled={record}
                          onClick={this.start}
                        >
                          <Icon name="microphone" />
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Header as="h3" className="">
                    Suggested topics to ask about:
                  </Header>
                  <Label.Group circular>
                    {subject &&
                      subject.suggestions
                        .filter(
                          (suggestion) =>
                            seenClipsById.includes(suggestion.clip.id) === false
                        )
                        .map((suggestion, index) => (
                          <Label key={index}>{suggestion.content}</Label>
                        ))}
                  </Label.Group>
                  <Divider />
                  {subject && (
                    <>
                      <Link
                        to={`/private/categories/${subject.category.slug}/${subject.slug}/about`}
                      >
                        <Button
                          className="action-btns"
                          color="green"
                          icon
                          labelPosition="left"
                          size="large"
                          fluid
                        >
                          <Icon name="info circle" />
                          About {subject && subject.displayName}
                        </Button>
                      </Link>
                      {subject.shareable && (
                        <>
                          <Button
                            className="action-btns"
                            color="twitter"
                            icon
                            labelPosition="left"
                            size="large"
                            fluid
                            onClick={() => this.shareTwitter(subject.slug)}
                          >
                            <Icon name="twitter" />
                            Share on Twitter
                          </Button>
                          <Button
                            className="action-btns"
                            color="facebook"
                            icon
                            labelPosition="left"
                            size="large"
                            fluid
                            onClick={() => this.shareFacebook(subject.slug)}
                          >
                            <Icon name="facebook" />
                            Share on Facebook
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Container>
              </Form>
            </Responsive>

            <Responsive {...Responsive.onlyTablet} className="mobile">
              <CustomHeader title={subject && subject.displayName} />
              <Form loading={isMobile && !this.state.isLoadingMobile}>
                {subject && subject.bannerImgUrl && (
                  <>
                    <img className="banner" src={subject.bannerImgUrl} />
                    <br />
                  </>
                )}
                <Responsive
                  // as={Form}
                  // loading={!isMobile && !this.state.isLoading}
                  className={!attemptMade ? "mobileDivHt" : "mobileDivHTUnset"}
                >
                  {subject && (
                    <p className={"videoWrapper"}>
                      <Image
                        disabled={!this.state.isLoadingMobile}
                        src={subject.thumbnailUrl}
                        className={"gif-stub " + (attemptMade ? "hidden" : "")}
                        onClick={() => {
                          this.state.isLoadingMobile ? this.startIntro() : "";
                        }}
                      />
                      <Image
                        disabled={!this.state.isLoadingMobile}
                        size="mini"
                        className={
                          "playbutton " + (attemptMade ? "hidden" : "")
                        }
                        src={playbutton}
                        onClick={() => {
                          this.state.isLoadingMobile ? this.startIntro() : "";
                        }}
                      />
                    </p>
                  )}

                  <div>
                    <Player
                      ref="player"
                      playsInline
                      className={attemptMade ? "" : "vis-hidden"}
                      startTime={videoStartTime}
                      fluid={true}
                      src={
                        subject &&
                        (subject.clipUrlMobile
                          ? subject.clipUrlMobile
                          : subject.clipUrl)
                      }
                      onLoadStart={() => {
                        this.setState({
                          isLoadingMobile: false,
                        });
                      }}
                      onLoadedMetadata={() => {
                        this.setState({
                          isLoadingMobile: true,
                        });
                      }}
                      onLoadedData={() => {
                        if (attemptMade) {
                          this.startIntro();
                        }
                      }}
                    >
                      <BigPlayButton
                        position="center"
                        className="big-play-button-hide"
                      />
                      <ControlBar disableCompletely={true} />
                    </Player>
                    {!this.state.isLoadingMobile && (
                      <div className="loadingMobileTextStyles">
                        Experience Loading...
                      </div>
                    )}
                  </div>
                </Responsive>
                <Container
                  className={
                    !this.state.textboxVisible ? "hidden" : "main-container"
                  }
                >
                  {!attemptMade && (
                    <Button
                      color="red"
                      size="large"
                      className="record-btn"
                      icon
                      fluid
                      labelPosition="left"
                      disabled={record}
                      onClick={this.start}
                    >
                      <Icon name="microphone" />
                      Tap to ask a question
                    </Button>
                  )}
                  <Grid
                    columns={2}
                    className={
                      !this.state.textboxVisible
                        ? "hidden"
                        : !attemptMade
                        ? "hidden"
                        : ""
                    }
                  >
                    <Grid.Row>
                      <Grid.Column width={12} className="left-col">
                        <Input
                          id="output"
                          fluid
                          disabled
                          value={statusText ? statusText : ""}
                        />
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Button
                          className="record-btn"
                          icon
                          fluid
                          disabled={record}
                          onClick={this.start}
                        >
                          <Icon name="microphone" />
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Header as="h3" className="">
                    Suggested topics to ask about:
                  </Header>
                  <Label.Group circular>
                    {subject &&
                      subject.suggestions
                        .filter(
                          (suggestion) =>
                            seenClipsById.includes(suggestion.clip.id) === false
                        )
                        .map((suggestion, index) => (
                          <Label key={index}>{suggestion.content}</Label>
                        ))}
                  </Label.Group>
                  <Divider />
                  {subject && (
                    <>
                      <Link
                        to={`/private/categories/${subject.category.slug}/${subject.slug}/about`}
                      >
                        <Button
                          className="action-btns"
                          color="green"
                          icon
                          labelPosition="left"
                          size="large"
                          fluid
                        >
                          <Icon name="info circle" />
                          About {subject && subject.displayName}
                        </Button>
                      </Link>
                      {subject.shareable && (
                        <>
                          <Button
                            className="action-btns"
                            color="twitter"
                            icon
                            labelPosition="left"
                            size="large"
                            fluid
                            onClick={() => this.shareTwitter(subject.slug)}
                          >
                            <Icon name="twitter" />
                            Share on Twitter
                          </Button>
                          <Button
                            className="action-btns"
                            color="facebook"
                            icon
                            labelPosition="left"
                            size="large"
                            fluid
                            onClick={() => this.shareFacebook(subject.slug)}
                          >
                            <Icon name="facebook" />
                            Share on Facebook
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Container>
              </Form>
            </Responsive>

            <Responsive {...Responsive.onlyComputer} className="desktop">
              <div className="main-content-holder">
                <CustomHeader />
                <div className="main-container">
                  {subject && subject.bannerImgUrl && (
                    <div className="banner-div">
                      <img className="banner" src={subject.bannerImgUrl} />
                    </div>
                  )}
                  <Grid className="header-controls-grid">
                    <Grid.Row>
                      <Grid.Column width={8} className="header-col">
                        <Header as="h1">
                          {subject && subject.displayName}
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={8} textAlign="right">
                        {subject && (
                          <Link
                            to={`/private/categories/${subject.category.slug}/${subject.slug}/about`}
                          >
                            <Button icon labelPosition="left" size="large">
                              <Icon name="info" />
                              About
                            </Button>
                          </Link>
                        )}
                        {subject && subject.shareable && (
                          <Dropdown
                            text="Share"
                            icon="share alternate"
                            floating
                            labeled
                            button
                            className="icon large"
                            size="large"
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                icon="twitter"
                                text="Twitter"
                                onClick={() => this.shareTwitter(subject.slug)}
                              />
                              <Dropdown.Item
                                icon="facebook"
                                text="Facebook"
                                onClick={() => this.shareFacebook(subject.slug)}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid columns={2}>
                    <Grid.Column width={12}>
                      <Responsive
                        as={Form}
                        loading={!isMobile && !this.state.isLoading}
                      >
                        {subject && (
                          <>
                            <Image
                              disabled={!this.state.isLoading}
                              src={subject.thumbnailUrl}
                              className={
                                "gif-stub " + (attemptMade ? "hidden" : "")
                              }
                              onClick={() => {
                                this.state.isLoading ? this.startIntro() : "";
                              }}
                            />
                            <Image
                              disabled={!this.state.isLoading}
                              size="mini"
                              className="playbutton"
                              src={playbutton}
                              onClick={() => {
                                this.state.isLoading ? this.startIntro() : "";
                              }}
                            />
                          </>
                        )}

                        <Player
                          ref="player"
                          playsInline
                          className={attemptMade ? "" : "hidden"}
                          startTime={videoStartTime}
                          src={subject && subject.clipUrl}
                          onLoadStart={() => {
                            this.setState({
                              isLoading: false,
                            });
                          }}
                          onLoadedData={() => {
                            this.setState({
                              isLoading: true,
                            });
                          }}
                        >
                          <BigPlayButton
                            position="center"
                            className="big-play-button-hide"
                          />
                          <ControlBar disableCompletely={true} />
                        </Player>
                        {!this.state.isLoading && (
                          <div className="loadingTextStyles">
                            Experience Loading...
                          </div>
                        )}
                      </Responsive>
                      <div
                        className={
                          !this.state.textboxVisible
                            ? "hidden"
                            : !attemptMade
                            ? "output-holder-pre-first-attempt"
                            : "output-holder-post-first-attempt"
                        }
                      >
                        <Input
                          id="output"
                          size="large"
                          value={statusText ? statusText : ""}
                          labelPosition="right"
                          fluid={true}
                          action={{
                            labelPosition: "left",
                            icon: "microphone",
                            className: "record-btn",
                            content: "Tap to ask a question",
                            disabled: record,
                            onClick: this.start,
                          }}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Header as="h3" className="">
                        Suggested topics to ask about:
                      </Header>
                      <Label.Group circular>
                        {subject &&
                          subject.suggestions
                            .filter(
                              (suggestion) =>
                                seenClipsById.includes(suggestion.clip.id) ===
                                false
                            )
                            .map((suggestion, index) => (
                              <Label key={index}>{suggestion.content}</Label>
                            ))}
                      </Label.Group>
                    </Grid.Column>
                  </Grid>
                </div>
              </div>
              <Footer />
            </Responsive>
          </div>
        ) : null}
      </>
    );
  }
}

export default compose(
  graphql(speechMutation, { name: "speechMutation" }),
  graphql(watsonMutation, { name: "watsonMutation" }),
  graphql(subjectQuery, {
    options: (props: OwnProps): QueryOpts<SubjectQueryParams> => ({
      variables: {
        subjectSlug: props.match.params.subjectSlug,
      },
    }),
  })
)(ViewExperienceView);
