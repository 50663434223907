import React from "react";

const publicIp = require("public-ip");
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { withApollo, graphql, compose, QueryOpts } from "react-apollo";
import gql from "graphql-tag";
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Image,
  Label,
  Responsive,
} from "semantic-ui-react";
import { BigPlayButton, ControlBar, Player } from "video-react";
import { RouteComponentProps } from "react-router";
import "video-react/dist/video-react.css";
import { ChildDataProps } from "react-apollo";

import { Mixpanel } from "src/mixpanel";
import Footer from "src/components/Footer";
import CustomHeaderForGuest from "src/components/CustomHeader/CustomHeaderForGuest";
import { generatePageTitle } from "src/utils";
import playbutton from "src/images/whiteplay.png";
import "./style.css";
import "./style.scss";
import {
  VIEW_EXPERIENCE_PAGE_LOADED,
  DOMAIN,
  VIDEO_INTERRUPTED,
  QUESTION_ASKED,
  CLIENT_ID,
  FROM,
  VIDEO_NAME,
  CLIP_NAME,
  ASKED_QUESTION,
  RESPONSE_START,
  RESPONSE_END,
  RESPONSE_RECEIVED,
  TOTAL_SECONDS_WATCHED,
  TIME_TAKEN_TO_ASK_QUESTION,
  INTERRUPTED_TIME,
  EVENT_DATE,
  EVENT_TIME,
  VIDEO_INTERRUPTED_COUNT,
  RESPONSE_WATCHED,
  ORIGINAL_QUESTION,
  OS_VERSION,
} from "src/constants";
import { isMobile } from "react-device-detect";
import CustomHeader from "src/components/CustomHeader";

var recognizeMic = require("watson-speech/speech-to-text/recognize-microphone");

const speechMutation = gql`
  mutation fetchClip($transcribedText: String!, $subjectId: Int!) {
    fetchClip(transcribedText: $transcribedText, subjectId: $subjectId) {
      id
      dialogflowIntentName
      startTimestampInSeconds
      endTimestampInSeconds
    }
  }
`;
const subjectQuery = gql`
  query publicSubjects($subjectSlug: String!) {
    publicSubjects(subjectSlug: $subjectSlug) {
      id
      displayName
      slug
      gifStubUrl
      gifStubUrlMobile
      clipUrl
      clipUrlMobile
      thumbnailUrl
      shareable
      idleStartTimestampInSeconds
      idleEndTimestampInSeconds
      dontKnowStartTimestampInSeconds
      dontKnowEndTimestampInSeconds
      suggestions {
        content
        clip {
          id
        }
      }
      displayName
      bannerImgUrl
      aboutText
      introStartTimestampInSeconds
      introEndTimestampInSeconds
      category {
        slug
      }
    }
  }
`;
const watsonMutation = gql`
  mutation fetchWatsonToken {
    fetchWatsonToken {
      watsonAccessToken
    }
  }
`;

enum Modes {
  IDLE,
  ANSWER,
  DONT_KNOW,
  INTRO,
}

type SubjectQueryParams = {
  subjectSlug: string;
};

type Clip = {
  id: number;
};

type Suggestion = {
  content: string;
  clip: Clip;
};

type PublicSubjectQueryResponse = {
  publicSubjects: [
    {
      id: number;
      displayName: string;
      suggestions: Array<Suggestion>;
      clipUrl: string;
      clipUrlMobile: string;
      thumbnailUrl: string;
      shareable: boolean;
      gifStubUrl: string;
      gifStubUrlMobile: string;
      slug: string;
      bannerImgUrl: string;
      idleStartTimestampInSeconds: number;
      idleEndTimestampInSeconds: number;
      dontKnowStartTimestampInSeconds: number;
      dontKnowEndTimestampInSeconds: number;
      introStartTimestampInSeconds: number;
      introEndTimestampInSeconds: number;
      category: {
        slug: string;
      };
    }
  ];
};

type FetchClipMutationResponse = {
  id: number;
  transcribedQuery: String;
  dialogflowIntentName: String;
  startTimestampInSeconds: number;
  endTimestampInSeconds: number;
};

type OwnProps = RouteComponentProps<SubjectQueryParams>;
type SubjectQueryProps = ChildDataProps<
  SubjectQueryParams,
  PublicSubjectQueryResponse
> & {
  speechMutation: Function;
  watsonMutation: Function;
};
type Props = SubjectQueryProps & OwnProps;

declare global {
  interface Window {
    AudioContext: any;
    webkitAudioContext: any;
    MSStream: any;
  }
}

type State = {
  currentClip: FetchClipMutationResponse | null | undefined;
  record: boolean;
  attemptMade: boolean;
  videoStartTime: number | null | undefined;
  mode: Modes;
  visible: boolean;
  error: boolean;
  processing: boolean;
  watsonAccessToken: string;
  showTranscribedTextHeader: boolean;
  seenClipsById: Array<number>;
  textboxVisible: boolean;
  isLoading: boolean;
  isLoadingMobile: boolean;
  pausedState: boolean;
  currentVideoTime: number | null | undefined;
  time: number;
  isOn: boolean;
  start: number;
  timer: any;
  recognizedText: any;
  loadPage: boolean;
  onPlay: boolean;
  isResponseReceived: string;
};

type mainData = {
  video_name: any;
  clip_name: string;
  asked_question: string;
  response_start: any;
  response_end: any;
  response_resceived: any;
  total_secounds_watched: any;
  time_taken_to_ask_question: string;
  interrupted_time: any;
  event_date: any;
  event_time: any;
  video_interrupted_count: any;
  original_question: any;
  os_version: any;
};

class ViewExperienceViewGuest extends React.Component<Props, State> {
  refs: any;
  state: State = {
    currentClip: null,
    record: false,
    videoStartTime: null,
    mode: Modes.IDLE,
    visible: true,
    error: false,
    processing: false,
    attemptMade: false,
    watsonAccessToken: "",
    showTranscribedTextHeader: false,
    seenClipsById: [],
    textboxVisible: false,
    isLoading: false,
    isLoadingMobile: false,
    pausedState: false,
    currentVideoTime: null,
    time: 0,
    isOn: false,
    start: 0,
    timer: "",
    recognizedText: "",
    loadPage: false,
    onPlay: false,
    isResponseReceived: "",
  };
  publicSubjects: any;
  introStart = 0;
  introEnd = 0;
  strDate: string | null | undefined;
  strTime: string | null | undefined;
  responseStartTime: any;
  responseEndTime: any;
  videoName = "";
  clipName = "";
  askedQuestion = "";
  originalQuestion: any;
  watchedTime: any;
  interruptedTime: any;
  interruptedCount: any;
  timeTakenToAsk: any;
  versionOs: any;

  detectOS = () => {
    var unknown = "-";

    // browser
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;

    // system
    var os = unknown;
    var clientStrings = [
      { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
      { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
      { s: "Windows Vista", r: /Windows NT 6.0/ },
      { s: "Windows Server 2003", r: /Windows NT 5.2/ },
      { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
      { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
      { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
      { s: "Windows 98", r: /(Windows 98|Win98)/ },
      { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
      { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: "Windows CE", r: /Windows CE/ },
      { s: "Windows 3.11", r: /Win16/ },
      { s: "Android", r: /Android/ },
      { s: "Open BSD", r: /OpenBSD/ },
      { s: "Sun OS", r: /SunOS/ },
      { s: "Chrome OS", r: /CrOS/ },
      { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
      { s: "iOS", r: /(iPhone|iPad|iPod)/ },
      { s: "Mac OS X", r: /Mac OS X/ },
      { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: "QNX", r: /QNX/ },
      { s: "UNIX", r: /UNIX/ },
      { s: "BeOS", r: /BeOS/ },
      { s: "OS/2", r: /OS\/2/ },
      {
        s: "Search Bot",
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    for (var id in clientStrings) {
      var cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    var osVersion: any = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)![1];
      os = "Windows";
    }

    switch (os) {
      case "Mac OS X":
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)![1];
        break;

      case "Android":
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)![1];
        break;

      case "iOS":
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)!;
        osVersion =
          osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
        break;
    }
    this.versionOs = os + " " + osVersion;
  };

  componentWillUnmount = () => {
    this.setState({ onPlay: false });
    this.stopTimer();
  };

  componentDidUpdate() {
    this.publicSubjects =
      this.props.data.publicSubjects && this.props.data.publicSubjects[0];

    if (
      (this.publicSubjects &&
      this.publicSubjects.clipUrlMobile &&
      this.state.isLoadingMobile === false) || (this.publicSubjects && this.publicSubjects.clipUrl && this.state.isLoading ===false)) {
      this.setState({
        isLoading: true,
        isLoadingMobile: true,
      });
    }
  }

  mainData(mainData: mainData) {
    return {
      [CLIENT_ID]: this.generateMixpanelValues(""),
      [FROM]: "Website",
      [VIDEO_NAME]: this.generateMixpanelValues(mainData.video_name),
      [CLIP_NAME]: this.generateMixpanelValues(mainData.clip_name),
      [ASKED_QUESTION]: this.generateMixpanelValues(mainData.asked_question),
      [RESPONSE_START]: this.generateMixpanelValues(mainData.response_start),
      [RESPONSE_END]: this.generateMixpanelValues(mainData.response_end),
      [RESPONSE_RECEIVED]: this.generateMixpanelValues(
        mainData.response_resceived
      ),
      [TOTAL_SECONDS_WATCHED]: this.generateMixpanelValues(
        mainData.total_secounds_watched
      ),
      [TIME_TAKEN_TO_ASK_QUESTION]: this.generateMixpanelValues(
        mainData.time_taken_to_ask_question
      ),
      [INTERRUPTED_TIME]: this.generateMixpanelValues(
        mainData.interrupted_time
      ),
      [EVENT_DATE]: this.generateMixpanelValues(mainData.event_date),
      [EVENT_TIME]: this.generateMixpanelValues(mainData.event_time),
      [VIDEO_INTERRUPTED_COUNT]: this.generateMixpanelValues(
        mainData.video_interrupted_count
      ),
      [ORIGINAL_QUESTION]: this.generateMixpanelValues(
        mainData.original_question
      ),
      [OS_VERSION]: this.generateMixpanelValues(mainData.os_version),
    };
  }

  componentDidMount = async () => {
    this.setState({ onPlay: true });
    this.setState({ loadPage: true });
    let ipAddress = await publicIp.v4();
    if (ipAddress == null || ipAddress == undefined || ipAddress == "") {
      ipAddress = await publicIp.v6();
    }
    var date = new Date();
    this.formatDate(date);
    if (this.state.loadPage && this.state.onPlay && this.refs.player) {
      this.refs.player.subscribeToStateChange(this.handleIdlePlayerStateChange);
    }
    let { data } = await this.props.watsonMutation();
    var mainData = this.mainData({
      video_name: this.videoName,
      clip_name: "",
      asked_question: "",
      response_start: "",
      response_end: "",
      response_resceived: "",
      total_secounds_watched: "",
      time_taken_to_ask_question: "",
      interrupted_time: "",
      event_date: this.strDate,
      event_time: this.strTime,
      video_interrupted_count: "",
      original_question: "",
      os_version: this.versionOs,
    });

    Mixpanel.identify(ipAddress);
    Mixpanel.people.set({ $name: ipAddress });
    Mixpanel.people.increment("Total_view");
    Mixpanel.track(VIEW_EXPERIENCE_PAGE_LOADED, mainData);
    this.setState({
      watsonAccessToken: data.fetchWatsonToken.watsonAccessToken,
    });
  };

  //exclude undefined and null values
  generateMixpanelValues(value: string | null | undefined) {
    if (
      value != null &&
      value != "" &&
      value != "null" &&
      value != "undefined" &&
      value != undefined
    )
      return value;
    else return "N/A";
  }

  //Timer Start
  startTimer() {
    this.setState({
      isOn: true,
      time: 0,
      start: Date.now() - this.state.time,
    });

    this.state.timer = setInterval(
      () =>
        this.setState({
          time: Date.now() - this.state.start,
        }),
      1
    );
  }

  stopTimer() {
    this.setState({ isOn: false });
    clearInterval(this.state.timer);
  }

  resetTimer() {
    this.setState({ time: 0, isOn: false });
  }

  //Timer code ends

  //formating date time
  formatDate(date: any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    // this.strDate = (month > 9 ? month : "0" + month) + "-" + (day > 9 ? day : "0" + day) + "-" + year;
    this.strDate = month + "-" + day + "-" + year;
    this.strTime =
      (hours > 9 ? hours : "0" + hours) +
      ":" +
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds);
    return (
      date.getMonth() +
      1 +
      "-" +
      date.getDate() +
      "-" +
      date.getFullYear() +
      "-" +
      this.strTime
    );
  }

  msToTime(s: any) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var seconds = s % 60;
    s = (s - seconds) / 60;
    var minutes = s % 60;
    var hours = (s - minutes) / 60;

    return (
      (hours > 9 ? hours : "0" + hours) +
      ":" +
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds)
    );
  }

  startIntro = () => {
    const { mode, currentClip, record } = this.state;
    this.introStart = 0;
    this.introEnd = 0;
    this.interruptedCount = 0;

    if (this.publicSubjects) {
      this.introStart = this.publicSubjects.introStartTimestampInSeconds;
      this.introEnd = this.publicSubjects.introEndTimestampInSeconds;
    }

    this.setState({
      videoStartTime: this.introStart,
      textboxVisible: true,
      mode: Modes.INTRO,
      error: false,
      processing: false,
      attemptMade: true,
    });

    this.clipName = "INTRO";

    if (this.introStart != null && this.introEnd != null) {
      let time = this.introEnd - this.introStart;
      Mixpanel.people.increment("Total_seconds", time);
    }
    this.refs.player && this.refs.player.seek(this.publicSubjects && this.introStart);
    this.refs.player && this.refs.player.play();
  };

  start = () => {
    const { attemptMade, seenClipsById } = this.state;
    this.startTimer();
    this.setState({
      videoStartTime:
        this.publicSubjects &&
        this.publicSubjects.idleStartTimestampInSeconds + 5,
      mode: Modes.IDLE,
    });

    var date = new Date();
    this.formatDate(date);

    if (this.state.currentVideoTime != null) {
      if (
        (this.publicSubjects &&
          this.state.currentVideoTime <
            this.publicSubjects.idleStartTimestampInSeconds &&
          this.state.currentVideoTime >
            this.publicSubjects.idleEndTimestampInSeconds) ||
        (this.publicSubjects &&
          this.state.currentVideoTime <
            this.publicSubjects.idleStartTimestampInSeconds &&
          this.state.currentVideoTime <
            this.publicSubjects.idleEndTimestampInSeconds) ||
        (this.publicSubjects &&
          this.state.currentVideoTime >
            this.publicSubjects.idleStartTimestampInSeconds &&
          this.state.currentVideoTime >
            this.publicSubjects.idleEndTimestampInSeconds)
      ) {
        Mixpanel.people.increment("Video interruption count");
        if (this.clipName == "INTRO") {
          this.state.isResponseReceived = "N/A";
          this.responseStartTime = this.introStart;
          this.responseEndTime = this.introEnd;
        } else {
          this.state.isResponseReceived = "Yes";
        }
        if (this.responseStartTime == 0) {
          this.responseStartTime = "0";
        }
        this.interruptedTime = parseInt(this.state.currentVideoTime.toString());
        this.interruptedCount++;
        this.mainData({
          video_name: this.videoName,
          clip_name: this.clipName,
          asked_question: this.askedQuestion,
          response_resceived: this.state.isResponseReceived,
          total_secounds_watched: "",
          time_taken_to_ask_question: "",
          interrupted_time: this.interruptedTime,
          event_date: this.strDate,
          event_time: this.strTime,
          video_interrupted_count: "1",
          original_question: this.originalQuestion,
          os_version: this.versionOs,
          response_start: this.responseStartTime,
          response_end: this.responseEndTime,
        });
      }
    }

    Mixpanel.people.increment("Total_Questions_Asked");

    this.refs.player && this.refs.player.seek(
      this.publicSubjects && this.publicSubjects.idleStartTimestampInSeconds + 5
    );

    // iOS compromise
    var isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      if (!attemptMade) {
        this.refs.player &&  this.refs.player.play();
        this.refs.player &&  this.refs.player.pause();
      }
    }

    this.setState({
      showTranscribedTextHeader: true,
      record: true,
      attemptMade: true,
      error: false,
      recognizedText: "",
    });

    var stream = recognizeMic({
      //access_token: this.state.watsonAccessToken,
      accessToken:  this.state.watsonAccessToken,
      outputElement: "#output",
      language_customization_id: "923757ac-136f-47b8-9aed-b08a58b27e2f",
    });

    stream.on("error", function (err: string) {
      console.log(err);
    });

    stream.on("data", async (data: any) => {
      this.resetTimer();
      //alert(data)
      console.log('data', data)
      this.state.recognizedText = data.results[0].alternatives[0].transcript;
      if (data.results[0] && data.results[0].final && this.state.onPlay) {
        stream.stop();
        this.setState({ record: false, processing: true });
        let res = await this.props.speechMutation({
          variables: {
            subjectId: this.publicSubjects && this.publicSubjects.id,
            transcribedText: data.results[0].alternatives[0].transcript,
          },
        });
        this.interruptedCount = 0;
        this.stopTimer();
        this.timeTakenToAsk = this.msToTime(this.state.time);
        this.resetTimer();
        this.askedQuestion = data.results[0].alternatives[0].transcript;
        if (
          res.data.fetchClip.endTimestampInSeconds != null &&
          res.data.fetchClip.startTimestampInSeconds != null
        ) {
          let time =
            res.data.fetchClip.endTimestampInSeconds -
            res.data.fetchClip.startTimestampInSeconds;
          Mixpanel.people.increment("Total_seconds", time);
        }

        var date = new Date();
        this.formatDate(date);

        this.clipName = res.data.fetchClip.dialogflowIntentName;
        let track_date = {
          video_name: this.videoName,
          clip_name: this.clipName,
          asked_question: this.askedQuestion,
          response_resceived: "No",
          total_secounds_watched: "",
          time_taken_to_ask_question: this.timeTakenToAsk,
          interrupted_time: "",
          event_date: this.strDate,
          event_time: this.strTime,
          video_interrupted_count: "",
          original_question: this.originalQuestion,
          os_version: this.versionOs,
          response_start: undefined,
          response_end: undefined,
        };
        if (res.data.fetchClip) {
          if (res.data.fetchClip.dialogflowIntentName === "ERROR") {
            if (
              this.publicSubjects &&
              (!this.publicSubjects.dontKnowStartTimestampInSeconds ||
                !this.publicSubjects.dontKnowEndTimestampInSeconds)
            ) {
              this.setState({
                mode: Modes.IDLE,
                error: true,
                processing: false,
              });

              var date = new Date();
              this.formatDate(date);

              if (this.publicSubjects) {
                this.responseStartTime =
                  res.data.fetchClip.startTimestampInSeconds;
                this.responseEndTime = res.data.fetchClip.endTimestampInSeconds;
                track_date.response_start = this.responseStartTime;
                track_date.response_end = this.responseEndTime;
                let main_data = this.mainData(track_date);
                Mixpanel.track(QUESTION_ASKED, main_data);
              }

              //IOS ONLY
              var isIOS =
                /iPad|iPhone|iPod/.test(navigator.userAgent) &&
                !window.MSStream;
              if (isIOS) {
                this.refs.player && this.refs.player.seek(
                  this.publicSubjects &&
                    this.publicSubjects.idleStartTimestampInSeconds + 5
                );
                this.refs.player && this.refs.player.play();
              }
            } else {
              this.setState({
                mode: Modes.DONT_KNOW,
                error: true,
                processing: false,
              });

              if (this.publicSubjects) {
                this.responseStartTime = this.publicSubjects.dontKnowStartTimestampInSeconds;
                this.responseEndTime = this.publicSubjects.dontKnowEndTimestampInSeconds;

                track_date.response_start = this.responseStartTime;
                track_date.response_end = this.responseEndTime;
                Mixpanel.track(QUESTION_ASKED, track_date);
              }

              //IOS ONLY
              var isIOS =
                /iPad|iPhone|iPod/.test(navigator.userAgent) &&
                !window.MSStream;
              if (isIOS) {
                this.refs.player && this.refs.player.seek(
                  this.publicSubjects &&
                    this.publicSubjects.dontKnowStartTimestampInSeconds
                );
                this.refs.player && this.refs.player.play();
              }
            }
          } else {
            this.setState({
              currentClip: res.data.fetchClip,
              videoStartTime:
                res.data.fetchClip &&
                res.data.fetchClip.startTimestampInSeconds,
              mode: Modes.ANSWER,
              processing: false,
              seenClipsById: [...seenClipsById, res.data.fetchClip.id],
            });
            this.originalQuestion = "";
            if (this.state.onPlay) {
              this.refs.player &&  this.refs.player.seek(
                res.data.fetchClip && res.data.fetchClip.startTimestampInSeconds
              );
            }

            // If the player was paused, this is needed
            this.refs.player && this.refs.player.play();

            if (this.publicSubjects) {
              this.publicSubjects.suggestions.map(
                (suggestion: any, index: number) => {
                  if (res.data.fetchClip.id == suggestion.clip.id) {
                    this.originalQuestion = suggestion.content;
                  }
                }
              );

              this.responseStartTime =
                res.data.fetchClip.startTimestampInSeconds;
              this.responseEndTime = res.data.fetchClip.endTimestampInSeconds;
              track_date.response_start = this.responseStartTime;
              track_date.response_end = this.responseEndTime;
              track_date.original_question = this.originalQuestion;
              track_date.response_resceived = "Yes";
              this.state.isResponseReceived = "Yes";
              Mixpanel.track(QUESTION_ASKED, track_date);
            }
          }
        } else {
          this.setState({
            currentClip: res.data.fetchClip,
            videoStartTime:
              res.data.fetchClip && res.data.fetchClip.startTimestampInSeconds,
            mode: Modes.ANSWER,
            processing: false,
            seenClipsById: [...seenClipsById, res.data.fetchClip.id],
          });
          this.originalQuestion = "";
          if (this.state.onPlay) {
            this.refs.player && this.refs.player.seek(
              res.data.fetchClip && res.data.fetchClip.startTimestampInSeconds
            );
          }

          // If the player was paused, this is needed
          this.refs.player && this.refs.player.play();

          if (this.publicSubjects) {
            this.publicSubjects.suggestions.map(
              (suggestion: any, index: number) => {
                if (res.data.fetchClip.id == suggestion.clip.id) {
                  this.originalQuestion = suggestion.content;
                }
              }
            );

            this.responseStartTime = res.data.fetchClip.startTimestampInSeconds;
            this.responseEndTime = res.data.fetchClip.endTimestampInSeconds;

            track_date.response_start = this.responseStartTime;
            track_date.response_end = this.responseEndTime;
            track_date.original_question = this.originalQuestion;
            track_date.response_resceived = "Yes";
            this.state.isResponseReceived = "Yes";
            Mixpanel.track(QUESTION_ASKED, track_date);
          }
        }
      }
    });
  };

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    if (!newProps.data.loading && newProps.data.publicSubjects) {
      return {
        videoStartTime:
          newProps.data.publicSubjects[0].idleStartTimestampInSeconds + 5,
      };
    }

    return prevState;
  }

  // Wait until the idle clip ends and then loop
  handleIdlePlayerStateChange = (state: any, prevState: any) => {
    const { mode, currentClip, record } = this.state;
    this.setState({ currentVideoTime: state.currentTime });
    let track_date = {
      video_name: this.videoName,
      clip_name: this.clipName,
      asked_question: this.askedQuestion,
      response_resceived: "No",
      total_secounds_watched: "",
      time_taken_to_ask_question: this.timeTakenToAsk,
      interrupted_time: "",
      event_date: this.strDate,
      event_time: this.strTime,
      video_interrupted_count: "",
      original_question: this.originalQuestion,
      os_version: this.versionOs,
      response_start: undefined,
      response_end: undefined,
    };
    var date = new Date();
    this.formatDate(date);

    if (currentClip != null || currentClip != undefined) {
      this.watchedTime =
        parseInt(state.currentTime) - currentClip.startTimestampInSeconds;
    }

    this.interruptedTime = parseInt(state.currentTime);

    if (prevState.paused != state.paused && state.paused == true) {
      this.interruptedCount++;
      Mixpanel.people.increment("Video interruption count");
      if (this.clipName == "INTRO") {
        this.state.isResponseReceived = "N/A";
        this.responseStartTime = this.introStart;
        this.responseEndTime = this.introEnd;
      } else {
        this.state.isResponseReceived = "Yes";
      }
      if (this.responseStartTime == 0) {
        this.responseStartTime = "0";
      }

      track_date.response_start = this.responseStartTime;
      track_date.response_end = this.responseEndTime;

      Mixpanel.track(VIDEO_INTERRUPTED, track_date);
    }

    if (
      mode === Modes.IDLE &&
      this.publicSubjects &&
      parseInt(state.currentTime) >=
        this.publicSubjects.idleEndTimestampInSeconds
    ) {
      if (
        this.publicSubjects.idleEndTimestampInSeconds != null &&
        this.publicSubjects.idleStartTimestampInSeconds != null
      ) {
        let time =
          this.publicSubjects.idleEndTimestampInSeconds -
          this.publicSubjects.idleStartTimestampInSeconds;
        Mixpanel.people.increment("Total_seconds", time);
      }
      this.refs.player &&  this.refs.player.seek(
        this.publicSubjects.idleStartTimestampInSeconds + 5
      );
    } else if (
      mode === Modes.INTRO &&
      this.publicSubjects &&
      parseInt(state.currentTime) >= this.introEnd
    ) {
      this.setState({
        videoStartTime:
          this.publicSubjects &&
          this.publicSubjects.idleStartTimestampInSeconds + 5,
        mode: Modes.IDLE,
      });
      this.refs.player && this.refs.player.seek(
        this.publicSubjects &&
          this.publicSubjects.idleStartTimestampInSeconds + 5
      );

      this.clipName = "INTRO";
      this.watchedTime = parseInt(state.currentTime) - this.introStart;
      this.responseStartTime = this.introStart;
      this.responseEndTime = this.introEnd;
      if (this.clipName == "INTRO") {
        this.state.isResponseReceived = "N/A";
      } else {
        this.state.isResponseReceived = "Yes";
      }

      track_date.response_start = this.responseStartTime;
      track_date.response_end = this.responseEndTime;
      track_date.response_resceived = this.state.isResponseReceived;
      track_date.total_secounds_watched = this.watchedTime;
      track_date.time_taken_to_ask_question = "";
      track_date.video_interrupted_count = this.interruptedCount;

      Mixpanel.track(RESPONSE_WATCHED, track_date);
    } else if (
      mode === Modes.ANSWER &&
      currentClip &&
      parseInt(state.currentTime) >= currentClip.endTimestampInSeconds
    ) {
      this.setState({
        videoStartTime:
          this.publicSubjects &&
          this.publicSubjects.idleStartTimestampInSeconds + 5,
        mode: Modes.IDLE,
      });
      this.refs.player && this.refs.player.seek(
        this.publicSubjects &&
          this.publicSubjects.idleStartTimestampInSeconds + 5
      );

      this.mainData({
        video_name: this.videoName,
        clip_name: this.clipName,
        asked_question: this.askedQuestion,
        response_resceived: "Yes",
        total_secounds_watched: this.watchedTime,
        time_taken_to_ask_question: "",
        interrupted_time: "",
        event_date: this.strDate,
        event_time: this.strTime,
        video_interrupted_count: this.interruptedCount,
        original_question: this.originalQuestion,
        os_version: this.versionOs,
        response_start: this.responseStartTime,
        response_end: this.responseEndTime,
      });
    } else if (mode === Modes.ANSWER && record) {
      this.refs.player && this.refs.player.pause();
    } else if (
      mode === Modes.DONT_KNOW &&
      this.publicSubjects &&
      parseInt(state.currentTime) >=
        this.publicSubjects.dontKnowEndTimestampInSeconds
    ) {
      this.setState({
        videoStartTime:
          this.publicSubjects &&
          this.publicSubjects.idleStartTimestampInSeconds + 5,
        mode: Modes.IDLE,
      });
      if (
        this.publicSubjects.dontKnowStartTimestampInSeconds != null &&
        this.publicSubjects.dontKnowEndTimestampInSeconds != null
      ) {
        let time =
          this.publicSubjects.dontKnowEndTimestampInSeconds -
          this.publicSubjects.dontKnowStartTimestampInSeconds;
        Mixpanel.people.increment("Total_seconds", time);
      }
      this.refs.player && this.refs.player.seek(
        this.publicSubjects &&
          this.publicSubjects.idleStartTimestampInSeconds + 5
      );
    }
  };

  onShow = () => {
    if (this.state.record) this.setState({ visible: false });
  };

  shareTwitter = (slug: string) => {
    window.open(`https://twitter.com/share?url=${DOMAIN}/${slug}`, "_blank");
  };

  shareFacebook = (slug: string) => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${DOMAIN}/${slug}`,
      "_blank"
    );
  };

  render() {
    const {
      attemptMade,
      videoStartTime,
      record,
      mode,
      error,
      processing,
      seenClipsById,
    } = this.state;

    this.videoName = "";
    if (this.publicSubjects) {
      this.videoName = this.publicSubjects.displayName;
    }

    let statusText = "";

    if (error) {
      statusText = "That didn't work, try again.";
    } else if (processing) {
      statusText = "Processing...";
    } else if (record) {
      statusText = "Start speaking into the mic";
      if (this.state.recognizedText != "") {
        statusText = this.state.recognizedText;
      }
    } else if (!attemptMade && mode === Modes.IDLE && !isMobile) {
      statusText = "Tap the button to ask a question.";
    } else if (mode === Modes.ANSWER || mode === Modes.IDLE) {
      statusText = "Tap the button to ask another question.";
    } else {
      statusText = "Tap the button to ask a question.";
    }

    const header = localStorage.getItem("jwt") ? (
      <CustomHeader
        title={this.publicSubjects && this.publicSubjects.displayName}
      />
    ) : (
      <CustomHeaderForGuest
        title={this.publicSubjects && this.publicSubjects.displayName}
      />
    );
    this.detectOS();

    return (
      <>
        {this.state.loadPage && this.state.onPlay ? (
          <div id="view-experience-view">
            <Helmet>
              <title>{generatePageTitle("View experience")}</title>
            </Helmet>
            <Responsive {...Responsive.onlyMobile} className="mobile">
              {header}
              <Form loading={Boolean(isMobile && !this.state.isLoadingMobile)}>
                {this.publicSubjects && this.publicSubjects.bannerImgUrl && (
                  <>
                    <img
                      className="banner"
                      src={this.publicSubjects.bannerImgUrl}
                    />
                    <br />
                  </>
                )}
                <Responsive
                  loading={Boolean(!isMobile && !this.state.isLoading)}
                  className={!attemptMade ? "mobileDivHt" : "mobileDivHTUnset"}
                >
                  {this.publicSubjects && (
                    <p className={"videoWrapper"}>
                      <Image
                        disabled={!this.state.isLoadingMobile}
                        src={this.publicSubjects.thumbnailUrl}
                        className={"gif-stub " + (attemptMade ? "hidden" : "")}
                        onClick={() => {
                          this.state.isLoadingMobile ? this.startIntro() : "";
                        }}
                      />
                      <Image
                        disabled={!this.state.isLoadingMobile}
                        size="mini"
                        className={
                          "playbutton " + (attemptMade ? "hidden" : "")
                        }
                        src={playbutton}
                        onClick={() => {
                          this.state.isLoadingMobile ? this.startIntro() : "";
                        }}
                      />
                    </p>
                  )}

                  <div>
                    <Player
                      ref="player"
                      playsInline
                      className={attemptMade ? "" : "vis-hidden"}
                      startTime={videoStartTime}
                      fluid={true}
                      src={
                        this.publicSubjects &&
                        (this.publicSubjects.clipUrlMobile
                          ? this.publicSubjects.clipUrlMobile
                          : this.publicSubjects.clipUrl)
                      }
                      onLoadStart={() => {
                        this.setState({
                          isLoadingMobile: false,
                        });
                      }}
                      onLoadedMetadata={() => {
                        this.setState({
                          isLoadingMobile: true,
                        });
                      }}
                      onLoadedData={() => {
                        if (attemptMade) {
                          this.startIntro();
                        }
                      }}
                    >
                      <BigPlayButton
                        position="center"
                        className="big-play-button-hide"
                      />
                      <ControlBar disableCompletely={true} />
                    </Player>
                    {!this.state.isLoadingMobile && (
                      <div className="loadingMobileTextStyles">
                        Experience Loading...
                      </div>
                    )}
                  </div>
                </Responsive>
                <Container
                  className={
                    !this.state.textboxVisible ? "hidden" : "main-container"
                  }
                >
                  {!attemptMade && (
                    <Button
                      color="red"
                      size="large"
                      className="record-btn"
                      icon
                      fluid
                      labelPosition="left"
                      disabled={record}
                      onClick={this.start}
                    >
                      <Icon name="microphone" />
                      Tap to ask a question
                    </Button>
                  )}
                  <Grid
                    columns={2}
                    className={
                      !this.state.textboxVisible
                        ? "hidden"
                        : !attemptMade
                        ? "hidden"
                        : ""
                    }
                  >
                    <Grid.Row>
                      <Grid.Column width={12} className="left-col">
                        <Input id="output" fluid disabled value={statusText} />
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Button
                          className="record-btn"
                          icon
                          fluid
                          disabled={record}
                          onClick={this.start}
                        >
                          <Icon name="microphone" />
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Header as="h3" className="">
                    Suggested topics to ask about:
                  </Header>
                  <Label.Group circular>
                    {this.publicSubjects &&
                      this.publicSubjects.suggestions
                        .filter(
                          (suggestion: any) =>
                            seenClipsById.includes(suggestion.clip.id) === false
                        )
                        .map((suggestion: any, index: number) => (
                          <Label key={index}>{suggestion.content}</Label>
                        ))}
                  </Label.Group>
                  <Divider />
                  {this.publicSubjects && (
                    <>
                      <Link
                        to={`/public/categories/${this.publicSubjects.category.slug}/${this.publicSubjects.slug}/about`}
                      >
                        <Button
                          className="action-btns"
                          color="green"
                          icon
                          labelPosition="left"
                          size="large"
                          fluid
                        >
                          <Icon name="info circle" />
                          About{" "}
                          {this.publicSubjects &&
                            this.publicSubjects.displayName}
                        </Button>
                      </Link>
                      {this.publicSubjects.shareable && (
                        <>
                          <Button
                            className="action-btns"
                            color="twitter"
                            icon
                            labelPosition="left"
                            size="large"
                            fluid
                            onClick={() =>
                              this.shareTwitter(this.publicSubjects.slug)
                            }
                          >
                            <Icon name="twitter" />
                            Share on Twitter
                          </Button>
                          <Button
                            className="action-btns"
                            color="facebook"
                            icon
                            labelPosition="left"
                            size="large"
                            fluid
                            onClick={() =>
                              this.shareFacebook(this.publicSubjects.slug)
                            }
                          >
                            <Icon name="facebook" />
                            Share on Facebook
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Container>
              </Form>
            </Responsive>

            <Responsive {...Responsive.onlyTablet} className="mobile">
              {header}
              <Form loading={Boolean(isMobile && !this.state.isLoadingMobile)}>
                {this.publicSubjects && this.publicSubjects.bannerImgUrl && (
                  <>
                    <img
                      className="banner"
                      src={this.publicSubjects.bannerImgUrl}
                    />
                    <br />
                  </>
                )}
                <Responsive
                  loading={Boolean(!isMobile && !this.state.isLoading)}
                  className={!attemptMade ? "mobileDivHt" : "mobileDivHTUnset"}
                >
                  {this.publicSubjects && (
                    <p className={"videoWrapper"}>
                      <Image
                        disabled={!this.state.isLoadingMobile}
                        src={this.publicSubjects.thumbnailUrl}
                        className={"gif-stub " + (attemptMade ? "hidden" : "")}
                        onClick={() => {
                          this.state.isLoadingMobile ? this.startIntro() : "";
                        }}
                      />
                      <Image
                        disabled={!this.state.isLoadingMobile}
                        size="mini"
                        className={
                          "playbutton " + (attemptMade ? "hidden" : "")
                        }
                        src={playbutton}
                        onClick={() => {
                          this.state.isLoadingMobile ? this.startIntro() : "";
                        }}
                      />
                    </p>
                  )}

                  <div>
                    <Player
                      ref="player"
                      playsInline
                      className={attemptMade ? "" : "vis-hidden"}
                      startTime={videoStartTime}
                      fluid={true}
                      src={
                        this.publicSubjects &&
                        (this.publicSubjects.clipUrlMobile
                          ? this.publicSubjects.clipUrlMobile
                          : this.publicSubjects.clipUrl)
                      }
                      onLoadStart={() => {
                        this.setState({
                          isLoadingMobile: false,
                        });
                      }}
                      onLoadedMetadata={() => {
                        this.setState({
                          isLoadingMobile: true,
                        });
                      }}
                      onLoadedData={() => {
                        if (attemptMade) {
                          this.startIntro();
                        }
                      }}
                    >
                      <BigPlayButton
                        position="center"
                        className="big-play-button-hide"
                      />
                      <ControlBar disableCompletely={true} />
                    </Player>
                    {!this.state.isLoadingMobile && (
                      <div className="loadingMobileTextStyles">
                        Experience Loading...
                      </div>
                    )}
                  </div>
                </Responsive>
                <Container
                  className={
                    !this.state.textboxVisible ? "hidden" : "main-container"
                  }
                >
                  {!attemptMade && (
                    <Button
                      color="red"
                      size="large"
                      className="record-btn"
                      icon
                      fluid
                      labelPosition="left"
                      disabled={record}
                      onClick={this.start}
                    >
                      <Icon name="microphone" />
                      Tap to ask a question
                    </Button>
                  )}
                  <Grid
                    columns={2}
                    className={
                      !this.state.textboxVisible
                        ? "hidden"
                        : !attemptMade
                        ? "hidden"
                        : ""
                    }
                  >
                    <Grid.Row>
                      <Grid.Column width={12} className="left-col">
                        <Input id="output" fluid disabled value={statusText} />
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Button
                          className="record-btn"
                          icon
                          fluid
                          disabled={record}
                          onClick={this.start}
                        >
                          <Icon name="microphone" />
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Header as="h3" className="">
                    Suggested topics to ask about:
                  </Header>
                  <Label.Group circular>
                    {this.publicSubjects &&
                      this.publicSubjects.suggestions
                        .filter(
                          (suggestion: any) =>
                            seenClipsById.includes(suggestion.clip.id) === false
                        )
                        .map((suggestion: any, index: number) => (
                          <Label key={index}>{suggestion.content}</Label>
                        ))}
                  </Label.Group>
                  <Divider />
                  {this.publicSubjects && (
                    <>
                      <Link
                        to={`/public/categories/${this.publicSubjects.category.slug}/${this.publicSubjects.slug}/about`}
                      >
                        <Button
                          className="action-btns"
                          color="green"
                          icon
                          labelPosition="left"
                          size="large"
                          fluid
                        >
                          <Icon name="info circle" />
                          About{" "}
                          {this.publicSubjects &&
                            this.publicSubjects.displayName}
                        </Button>
                      </Link>
                      {this.publicSubjects.shareable && (
                        <>
                          <Button
                            className="action-btns"
                            color="twitter"
                            icon
                            labelPosition="left"
                            size="large"
                            fluid
                            onClick={() =>
                              this.shareTwitter(this.publicSubjects.slug)
                            }
                          >
                            <Icon name="twitter" />
                            Share on Twitter
                          </Button>
                          <Button
                            className="action-btns"
                            color="facebook"
                            icon
                            labelPosition="left"
                            size="large"
                            fluid
                            onClick={() =>
                              this.shareFacebook(this.publicSubjects.slug)
                            }
                          >
                            <Icon name="facebook" />
                            Share on Facebook
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Container>
              </Form>
            </Responsive>
            <Responsive {...Responsive.onlyComputer} className="desktop">
              <div className="main-content-holder">
                {header}
                <div className="main-container">
                  {this.publicSubjects && this.publicSubjects.bannerImgUrl && (
                    <div className="banner-div">
                      <img
                        className="banner"
                        src={this.publicSubjects.bannerImgUrl}
                      />
                    </div>
                  )}
                  <Grid className="header-controls-grid">
                    <Grid.Row>
                      <Grid.Column width={8} className="header-col">
                        <Header as="h1">
                          {this.publicSubjects &&
                            this.publicSubjects.displayName}
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={8} textAlign="right">
                        {this.publicSubjects && (
                          <Link
                            to={`/public/categories/${this.publicSubjects.category.slug}/${this.publicSubjects.slug}/about`}
                          >
                            <Button icon labelPosition="left" size="large">
                              <Icon name="info" />
                              About
                            </Button>
                          </Link>
                        )}
                        {this.publicSubjects && this.publicSubjects.shareable && (
                          <Dropdown
                            text="Share"
                            icon="share alternate"
                            floating
                            labeled
                            button
                            className="icon large"
                            size="large"
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                icon="twitter"
                                text="Twitter"
                                onClick={() =>
                                  this.shareTwitter(this.publicSubjects.slug)
                                }
                              />
                              <Dropdown.Item
                                icon="facebook"
                                text="Facebook"
                                onClick={() =>
                                  this.shareFacebook(this.publicSubjects.slug)
                                }
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid columns={2}>
                    <Grid.Column width={12}>
                      <Responsive
                        as={Form}
                        loading={Boolean(!isMobile && !this.state.isLoading)}
                      >
                        {this.publicSubjects && (
                          <>
                            <Image
                              disabled={!this.state.isLoading}
                              src={this.publicSubjects.thumbnailUrl}
                              className={
                                "gif-stub " + (attemptMade ? "hidden" : "")
                              }
                              onClick={() => {
                                this.state.isLoading ? this.startIntro() : "";
                              }}
                            />
                            <Image
                              disabled={!this.state.isLoading}
                              size="mini"
                              className="playbutton"
                              src={playbutton}
                              onClick={() => {
                                this.state.isLoading ? this.startIntro() : "";
                              }}
                            />
                          </>
                        )}

                        <Player
                          ref="player"
                          playsInline
                          className={attemptMade ? "" : "hidden"}
                          startTime={videoStartTime}
                          src={
                            this.publicSubjects && this.publicSubjects.clipUrl
                          }
                          onLoadStart={() => {
                            this.setState({
                              isLoading: false,
                            });
                          }}
                          onLoadedData={() => {
                            this.setState({
                              isLoading: true,
                            });
                          }}
                        >
                          <BigPlayButton
                            position="center"
                            className="big-play-button-hide"
                          />
                          <ControlBar disableCompletely={true} />
                        </Player>
                        {!this.state.isLoading && (
                          <div className="loadingTextStyles">
                            Experience Loading...
                          </div>
                        )}
                      </Responsive>
                      <div
                        className={
                          !this.state.textboxVisible
                            ? "hidden"
                            : !attemptMade
                            ? "output-holder-pre-first-attempt"
                            : "output-holder-post-first-attempt"
                        }
                      >
                        <Input
                          id="output"
                          size="large"
                          value={statusText}
                          labelPosition="right"
                          fluid={true}
                          action={{
                            labelPosition: "left",
                            icon: "microphone",
                            className: "record-btn",
                            content: "Tap to ask a question",
                            disabled: record,
                            onClick: this.start,
                          }}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Header as="h3" className="">
                        Suggested topics to ask about:
                      </Header>
                      <Label.Group circular>
                        {this.publicSubjects &&
                          this.publicSubjects.suggestions
                            .filter(
                              (suggestion: any) =>
                                seenClipsById.includes(suggestion.clip.id) ===
                                false
                            )
                            .map((suggestion: any, index: number) => (
                              <Label key={index}>{suggestion.content}</Label>
                            ))}
                      </Label.Group>
                    </Grid.Column>
                  </Grid>
                </div>
              </div>
              <Footer />
            </Responsive>
          </div>
        ) : null}
      </>
    );
  }
}

export default compose(
  graphql(speechMutation, { name: "speechMutation" }),
  graphql(watsonMutation, { name: "watsonMutation" }),
  graphql(subjectQuery, {
    options: (props: OwnProps): QueryOpts<SubjectQueryParams> => ({
      variables: {
        subjectSlug: props.match.params.subjectSlug,
      },
    }),
  })
)(ViewExperienceViewGuest);
