const publicIp = require('public-ip')
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { withApollo, graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Responsive,
  Transition,
} from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router'
import queryString from 'query-string'

import { Mixpanel } from '../../mixpanel'
import { generatePageTitle } from '../../utils'
import { SERVER_URL } from '../../constants'
import { GOOGLE_OAUTH_REDIRECT_PAGE_LOADED } from '../../constants'
import CustomHeader from '../../components/CustomHeader'

interface LoginJWT {
  jwt: string
  cat: string
}

class GoogleOauthRedirectView extends React.Component<RouteComponentProps<{}>, {}> {

  componentDidMount = async () => {
    let ipAddress = await publicIp.v4()
    if (ipAddress == null || ipAddress == undefined || ipAddress == '') {
      ipAddress = await publicIp.v6()
    }
    Mixpanel.identify(ipAddress)
    Mixpanel.people.set({ $name: ipAddress })
    Mixpanel.track(GOOGLE_OAUTH_REDIRECT_PAGE_LOADED)
      const ps = queryString.parse(this.props.location.search)
      const config = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code: ps.code, state: ps.state }),
    }
    console.log(SERVER_URL);
      const response = await fetch(`${SERVER_URL}/accounts/google/`, config)

    const json: LoginJWT = await response.json()
      if (json.jwt) {
      localStorage.setItem('jwt', json.jwt)
      localStorage.setItem('cat', json.cat)

      this.props.history.push('/categories')
    }
  }

  render() {
    return (
      <div id="google-oauth-redirect-view">
        <Helmet>
          <title>{generatePageTitle('Processing...')}</title>
        </Helmet>
        <Responsive {...Responsive.onlyMobile}>
          <CustomHeader />
          <p>Processing...</p>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          <CustomHeader />
          <p>Processing...</p>
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          <p>Processing...</p>
        </Responsive>
      </div>
    )
  }
}

export default withRouter(GoogleOauthRedirectView)
