import React from 'react'
const publicIp = require('public-ip')
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ChildDataProps, graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import {
  Button,
  Divider,
  Grid,
  Image,
  Message,
  Responsive,
} from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

import { RouteComponentProps } from 'react-router'
import queryString from 'query-string'

import { Mixpanel } from 'src/mixpanel'
import { generatePageTitle } from 'src/utils'
import Footer from 'src/components/Footer'
import { SIGNUP_EMAIL_PAGE_LOADED } from 'src/constants'
import logo from 'src/images/Aura-Logo-White.png'
import './style.css'

type State = {
  isLoading: boolean
  email: string
  password: string
  errorMsg: string
}

const signupMutation = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $category: String!
    $gender: String!
    $yearOfBirth: String!
  ) {
    createUser(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      category: $category
      gender: $gender
      yearOfBirth: $yearOfBirth
    ) {
      error
    }
  }
`

type SignupMutationResponse = {
  error: string
}

type SignupMutationParams = {
  email: string
  firstName: string
  lastName: string
  password: string
  category: string
  gender: string
  yearOfBirth: number
}

type OwnProps = RouteComponentProps<{}>
type Props = ChildDataProps<SignupMutationParams, SignupMutationResponse> & {
  signupMutation: Function
  loginMutation: Function
}

const loginMutation = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      error
      token
      category
    }
  }
`


const options = [
  { key: 'm', text: 'Male', value: 'male' },
  { key: 'f', text: 'Female', value: 'female' },
  { key: 'o', text: 'Other', value: 'other' },
  { key: 'p', text: 'Prefer Not to Specify', value: 'Prefer Not to Specify' }
]

const bornYears = [
  { key: 0, text: 0, value: 0 },
]


class SignupEmailView extends React.Component<OwnProps & Props, State> {
  state = {
    isLoading: false,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    gender: '',
    yearOfBirth: 0,
    errorMsg: '',
  }
  startYear = 1900
  endYear = 2030


  componentDidMount = async () => {
    let ipAddress = await publicIp.v4()
    var i = 0, bornYearStart = 1900;

    if (ipAddress == null || ipAddress == undefined || ipAddress == '') {
      ipAddress = await publicIp.v6()
    }

    Mixpanel.identify(ipAddress)
    Mixpanel.people.set({ $name: ipAddress })

    Mixpanel.track(SIGNUP_EMAIL_PAGE_LOADED)
    if (localStorage.getItem('jwt')) {
      this.props.history.push('/')
    }

    this.setState({ isLoading: true })
    for (i = 0; i <= (this.endYear - this.startYear); i++) {
      bornYears[i] = {
        key: bornYearStart, text: bornYearStart, value: bornYearStart
      }
      bornYearStart++;
    }
    if (bornYears.length > 1) {
      this.setState({ isLoading: false })
    }
  }

  onValidSubmit = async () => {
    this.setState({ errorMsg: '', isLoading: true })
    const { email, password, firstName, lastName, gender, yearOfBirth } = this.state
    const ps = queryString.parse(this.props.location.search)

    let { data } = await this.props.signupMutation({
      variables: { email, password, firstName, lastName, gender, yearOfBirth, category: ps.cat ? ps.cat : '' },
    })
    this.setState({ isLoading: false })

    if (!data.createUser.error) {
      this.setState({ errorMsg: '', isLoading: true })
      let { data } = await this.props.loginMutation({
        variables: { email, password },
      })
      this.setState({ isLoading: false })

      if (!data.loginUser.error) {
        localStorage.setItem('jwt', data.loginUser.token)
        localStorage.setItem('cat', data.loginUser.category)
        this.props.history.push(`/`)
      } else {
        this.setState({ errorMsg: 'There was an error logging in.' })
      }
    } else {
      this.setState({ errorMsg: 'There was an error signing up.' })
    }
  }

  onFieldChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.currentTarget
    const newVal = type === 'checkbox' ? checked : value
    this.setState(prevState => ({
      ...prevState,
      [name]: newVal,
    }))
  }

  getGender = (event: any, { value }: any) => {
    const newVal = event.target.textContent;
    this.setState(prevState => ({
      ...prevState,
      gender: newVal,
    }))
  }

  getBornYear = (event: any, { value }: any) => {
    const newVal = value;
    this.setState(prevState => ({
      ...prevState,
      yearOfBirth: newVal,
    }))
  }

  render() {
    const { isLoading, errorMsg, } = this.state

    return (
      <div id="signup-email-view">
        <Helmet>
          <title>{generatePageTitle('Signup with Email')}</title>
        </Helmet>
        <Responsive as={Grid} className="mobile-top-level-container" {...Responsive.onlyMobile}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className="main-content-holder">
                <Grid>
                  <Grid.Row columns={1} className="">
                    <Grid.Column>
                      <Link to="/">
                        <Image className="home-logo" src={logo} />
                      </Link>
                      <Form
                        className="form"
                        loading={isLoading}
                        onValidSubmit={this.onValidSubmit}
                        error={errorMsg ? true : false}
                      >
                        <Message error color="black" header="Oops" content={errorMsg} />
                        <Form.Input
                          name="firstName"
                          label="First name"
                          placeholder="First name"
                          required
                          onChange={this.onFieldChange}
                        />
                        <Form.Input
                          name="lastName"
                          label="Last name"
                          placeholder="Last name"
                          required
                          onChange={this.onFieldChange}
                        />
                        <Form.Input
                          name="email"
                          label="Email"
                          validations="isEmail"
                          placeholder="Email address"
                          required
                          onChange={this.onFieldChange}
                          validationErrors={{
                            isEmail: 'Email not valid',
                          }}
                        />
                        <Form.Input
                          name="password"
                          label="Password"
                          placeholder="Password"
                          required
                          type="password"
                          onChange={this.onFieldChange}
                        />
                        <Form.Dropdown
                          label='Gender'
                          placeholder='Gender'
                          name='gender'
                          fluid
                          selection
                          options={options}
                          onChange={this.getGender}
                        />
                        <Form.Dropdown
                          label='Year born'
                          placeholder='Year born'
                          name='yearOfBirth'
                          fluid
                          selection
                          options={bornYears}
                          onChange={this.getBornYear}
                        />
                        <Button content="Sign up" size="large" disabled={isLoading} />

                        <Divider className="divider" />
                        <span className="signup-text">
                          Already have an account?{' '}
                          <Link className="signup-text" to="/">
                            Sign in here.
                          </Link>
                        </span>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Responsive>
        <Responsive as={Grid} className="mobile-top-level-container" {...Responsive.onlyTablet}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className="main-content-holder">
                <Grid>
                  <Grid.Row columns={1} className="">
                    <Grid.Column>
                      <Link to="/">
                        <Image className="home-logo" src={logo} />
                      </Link>
                      <Form
                        className="form"
                        loading={isLoading}
                        onValidSubmit={this.onValidSubmit}
                        error={errorMsg ? true : false}
                      >
                        <Message error color="black" header="Oops" content={errorMsg} />
                        <Form.Input
                          name="firstName"
                          label="First name"
                          placeholder="First name"
                          required
                          onChange={this.onFieldChange}
                        />
                        <Form.Input
                          name="lastName"
                          label="Last name"
                          placeholder="Last name"
                          required
                          onChange={this.onFieldChange}
                        />
                        <Form.Input
                          name="email"
                          label="Email"
                          validations="isEmail"
                          placeholder="Email address"
                          required
                          onChange={this.onFieldChange}
                          validationErrors={{
                            isEmail: 'Email not valid',
                          }}
                        />
                        <Form.Input
                          name="password"
                          label="Password"
                          placeholder="Password"
                          required
                          type="password"
                          onChange={this.onFieldChange}
                        />
                        <Form.Dropdown
                          label='Gender'
                          placeholder='Gender'
                          name='gender'
                          fluid
                          selection
                          options={options}
                          onChange={this.getGender}
                        />
                        <Form.Dropdown
                          label='Year born'
                          placeholder='Year born'
                          name='yearOfBirth'
                          fluid
                          selection
                          options={bornYears}
                          onChange={this.getBornYear}
                        />
                        <Button content="Sign up" size="large" disabled={isLoading} />

                        <Divider className="divider" />
                        <span className="signup-text">
                          Already have an account?{' '}
                          <Link className="signup-text" to="/">
                            Sign in here.
                          </Link>
                        </span>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Responsive>
        <Responsive className="desktop-top-level-container" {...Responsive.onlyComputer}>
          <div className="main-content-holder">
            <Grid>
              <Grid.Row columns={1} className="">
                <Grid.Column>
                  <Link to="/">
                    <Image className="home-logo" src={logo} />
                  </Link>
                  <Form
                    className="form"
                    loading={isLoading}
                    onValidSubmit={this.onValidSubmit}
                    error={errorMsg ? true : false}
                  >
                    <Message error color="black" header="Oops" content={errorMsg} />
                    <Form.Input
                      name="firstName"
                      label="First name"
                      placeholder="First name"
                      required
                      onChange={this.onFieldChange}
                    />
                    <Form.Input
                      name="lastName"
                      label="Last name"
                      placeholder="Last name"
                      required
                      onChange={this.onFieldChange}
                    />
                    <Form.Input
                      name="email"
                      label="Email"
                      validations="isEmail"
                      placeholder="Email address"
                      required
                      onChange={this.onFieldChange}
                      validationErrors={{
                        isEmail: 'Email not valid',
                      }}
                    />
                    <Form.Input
                      name="password"
                      label="Password"
                      placeholder="Password"
                      required
                      type="password"
                      onChange={this.onFieldChange}
                    />
                    <Form.Dropdown
                      label='Gender'
                      placeholder='Gender'
                      name='gender'
                      fluid
                      selection
                      options={options}
                      onChange={this.getGender}
                    />
                    <Form.Dropdown
                      label='Year born'
                      placeholder='Year born'
                      name='yearOfBirth'
                      fluid
                      selection
                      options={bornYears}
                      onChange={this.getBornYear}
                    />

                    <Button content="Sign up" size="large" disabled={isLoading} />

                    <Divider className="divider" />
                    <span className="signup-text">
                      Already have an account?{' '}
                      <Link className="signup-text" to="/">
                        Sign in here.
                      </Link>
                    </span>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <Footer />
        </Responsive>
      </div>
    )
  }
}

export default compose(
  graphql(signupMutation, { name: 'signupMutation' }),
  graphql(loginMutation, { name: 'loginMutation' })
)(SignupEmailView)
