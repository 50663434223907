import React from 'react'
const publicIp = require('public-ip')
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Container } from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router'

import { Mixpanel } from 'src/mixpanel'
import { generatePageTitle } from 'src/utils'
import { LOGOUT_PAGE_LOADED } from 'src/constants'

class LogoutView extends React.Component<RouteComponentProps, {}> {
  componentDidMount = async () => {
    let ipAddress = await publicIp.v4()

    if (ipAddress == null || ipAddress == undefined || ipAddress == '') {
      ipAddress = await publicIp.v6()
    }

    Mixpanel.identify(ipAddress)
    Mixpanel.people.set({ $name: ipAddress })

    Mixpanel.track(LOGOUT_PAGE_LOADED)
    sessionStorage.removeItem('Firstname'),
    sessionStorage.removeItem('Lastname'),
    sessionStorage.removeItem('Is-active'),
    sessionStorage.removeItem('Email'),
    sessionStorage.removeItem('Gender'),
    sessionStorage.removeItem('Last-login'),
    sessionStorage.removeItem('Date-joined'),
    sessionStorage.removeItem('User-session'),
    sessionStorage.removeItem('Born-year')

    localStorage.removeItem('jwt')
    localStorage.removeItem('cat')
    this.props.history.push('/')
  }

  render() {
    return (
      <Container id="logout-view">
        <Helmet>
          <title>{generatePageTitle('Logging out...')}</title>
        </Helmet>
        Logging out...
      </Container>
    )
  }
}

export default withRouter(LogoutView)
