import React from "react";
import { Container, Icon, Image, Menu, Responsive } from "semantic-ui-react";
import { slide as SideMenu } from "react-burger-menu";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import logo from "src/images/Aura-Logo-White.png";
import hamburgerIcon from "src/images/hamburger_icon.png";
import "./style.css";

type Props = {
  title?: string;
};

// TODO - don't key off the pathname

class CustomHeader extends React.Component<
  RouteComponentProps<{}> & Props,
  {}
> {
  state = {
    visible: false,
  };

  toggle = () => {
    this.setState({ visible: !this.state.visible });
  };

  navigateTo = (path: string) => {
    this.props.history.push(path);

    // Put this so that if user navigates to current page, the menu
    // still closes
    this.toggle();
  };

  render() {
    const { title, location } = this.props;

    let myTitle;
    if (title) {
      myTitle = <p className="view-title">{this.props.title}</p>;
    } else if (location.pathname === "/oauth2callback") {
      myTitle = <Image size="mini" src={logo} />;
    } else if (location.pathname === "/categories") {
      myTitle = <p className="view-title">Topics</p>;
    }

    return (
      <>
        <Responsive id="mobile-header-component" {...Responsive.onlyMobile}>
          <Menu secondary size="small" className="mobile-top-menu">
            <Menu.Item className="home">{myTitle}</Menu.Item>
            <Menu.Item
              name="gamepad"
              onClick={this.toggle}
              className="hamburger"
            >
              <Image size="mini" src={hamburgerIcon} />
            </Menu.Item>
          </Menu>
          <SideMenu
            isOpen={this.state.visible}
            customBurgerIcon={false}
            customCrossIcon={false}
            width={120}
          >
            <Menu icon="labeled" inverted>
              <Menu.Item
                name="home"
                className="icon-line"
                onClick={() => this.navigateTo("/categories")}
              >
                <Icon name="list" />
                Categories
              </Menu.Item>
              <Menu.Item
                name="close"
                onClick={() => this.navigateTo("/logout")}
              >
                <Icon name="close" />
                Log out
              </Menu.Item>
            </Menu>
          </SideMenu>
        </Responsive>
        <Responsive id="mobile-header-component" {...Responsive.onlyTablet}>
          <Menu secondary size="small" className="mobile-top-menu">
            <Menu.Item className="home">{myTitle}</Menu.Item>
            <Menu.Item
              name="gamepad"
              onClick={this.toggle}
              className="hamburger"
            >
              <Image size="mini" src={hamburgerIcon} />
            </Menu.Item>
          </Menu>
          <SideMenu
            isOpen={this.state.visible}
            customBurgerIcon={false}
            customCrossIcon={false}
            width={120}
          >
            <Menu icon="labeled" inverted>
              <Menu.Item
                name="home"
                className="icon-line"
                onClick={() => this.navigateTo("/categories")}
              >
                <Icon name="list" />
                Categories
              </Menu.Item>
              <Menu.Item
                name="close"
                onClick={() => this.navigateTo("/logout")}
              >
                <Icon name="close" />
                Log out
              </Menu.Item>
            </Menu>
          </SideMenu>
        </Responsive>
        <Responsive id="desktop-header-component" {...Responsive.onlyComputer}>
          <Menu secondary size="small" className="desktop-top-menu">
            <Container>
              <Menu.Item onClick={() => this.navigateTo("/")}>
                <Image size="mini" src={logo} />
              </Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item
                  active={location.pathname === "/categories"}
                  className="action-item"
                  onClick={() => this.navigateTo("/categories")}
                >
                  <Icon name="list" />
                  Categories
                </Menu.Item>
                <Menu.Item
                  className="action-item"
                  onClick={() => this.navigateTo("/logout")}
                >
                  <Icon name="close" />
                  Log out
                </Menu.Item>
              </Menu.Menu>
            </Container>
          </Menu>
        </Responsive>
      </>
    );
  }
}

export default withRouter(CustomHeader);
